import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateISBN } from "./components/utils.tsx";
import Popover from "@material-ui/core/Popover";
import JsBarcode from "jsbarcode";
import { fabric } from "fabric";
import { addToHistory,removeStates } from "./components/CanvasHistory.tsx";


const ISBNMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [valid, setValid] = useState(true);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const isbn = useSelector((state: any) => {
    return state.updateIsbn;
  });
  const dimensions = useSelector((state: any) => {
    return state.dimensions;
  });

  useEffect(() => {
    let isbnFound = props.canvas?.stage.getObjects().find((item: any) => {
      return item.name === "isbn";
    });
    props.canvas?.stage.remove(isbnFound);
    let ISBN = validateISBN(isbn);
    if (ISBN) 
    
    {
      setValid(true)

      
      var canvass = document.createElement("canvas");
    if (canvass)
      JsBarcode(canvass, ISBN?.asIsbn13(), {
        format: "EAN13",
        marginLeft: 50,
        marginRight: 50,
      });
    fabric.Image.fromURL(canvass?.toDataURL("image/png"), function (isbn: any) {
      let myisbn = isbn;
      myisbn.set({
        left: dimensions.leftspine - 600,
        top: dimensions.height - 500,
        hasControls: true,
        name: "isbn",
      });
      addToHistory(props.canvas?.stage)
      removeStates()

      props.canvas?.add(myisbn);
      props.canvas?.stage.requestRenderAll();
      
    });
  }else{
    setValid(false)
  }
  }, [isbn]);

  return (
    <>
      <div
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        ISBN
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "5px" }}>
          <form>
            <input
            value={isbn}
              className={`form-control ${(isbn && valid?'is-valid':'is-invalid')}`}
              name="isbn"
              onChange={(event) => {
                dispatch({ type: "UPDATE_ISBN", payload: event.target.value });
              }}
            />

          </form>
        </div>
      </Popover>
    </>
  );
};

export default ISBNMenu;
