import {fabric} from 'fabric'
import { addToHistory,removeStates } from '../components/CanvasHistory.tsx';


class FBCanvas{
    public stage=null;
    constructor(canvas:any){
        this.stage=canvas
    }


add(object:fabric.Rect|fabric.Textbox|fabric.Image|fabric.Path){
  
     object.setCoords();
    this.stage.add(object).requestRenderAll();
    addToHistory(this.stage)
}


addImage(url,props){
    fabric.Image.fromURL(url,(oImg) =>{
        oImg.set(props)
        if(props.filters){
        oImg.filters  = props.filters 
        oImg?.applyFilters()
            this.stage.renderAll()
        }
        this.add(oImg);
      },{crossOrigin:"anonymous"})


      this.stage.requestRenderAll()
      removeStates()

    }


addRect(props:any){
 const rect=new fabric.Rect(props)
 this.add(rect);
 this.stage.renderAll()
}

addText(font:any,props:any){
    const text=new fabric.Textbox(font,props)

    this.add(text);
}
getSelectedObject(){
    this.stage.getActiveObject()
    removeStates()
}


}

export default FBCanvas;