import React, { useCallback, useRef } from 'react'
import { fabric } from 'fabric'
fabric.Object.prototype.metaType = null

const useFabric = (onChange,options) => {
const fabricRef=useRef(null);
const disposeRef=useRef(null);

  return (
    useCallback((node:any)=>{
        if(node)
        {
        fabricRef.current=new fabric.Canvas(node,options);
        if(onChange)
        {
            disposeRef.current=onChange(fabricRef.current)
        }
      }
      else if(fabricRef)
      {
          fabricRef.current.dispose()
          if(disposeRef)
          {
              disposeRef.current=undefined;
          }
      }

    },[])
  )
}

export default useFabric