import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'
import TextToolBar from './TextToolBar.tsx'
import Bookspecification from './Bookspecification.tsx'
import AddImage from './AddImage.tsx'
import { ChromePicker} from 'react-color'
import UndoRedo from './UndoRedo.tsx'
import Grid from './Grid.tsx'

import { getCoverSize } from './utils.tsx'
import { fabric } from 'fabric'
import * as constants from '../constants.tsx'
import { jsPDF } from 'jspdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBook,
    faBrush,
    faFont,
    faTrashCan,
    faDownload,
    faCircleInfo,
    faEye,
    faPaperclip,
} from '@fortawesome/free-solid-svg-icons'

import Popover from '@material-ui/core/Popover'
import ISBNMenu from '../ISBNMenu.tsx'
import ImageToolbar from './ImageToolbar.tsx'
import BackgroundImage from './BackgroundImage.tsx'
import masterblockslogo from '../logo/logo.png'
import { DownloadPDF } from './Download.tsx'

import 'intro.js/introjs.css'
import { Steps } from 'intro.js-react'
import { addToHistory, resetHistory, removeStates } from './CanvasHistory.tsx'
import { left } from '@popperjs/core'
import ZoomBar from './ZoomBar.tsx'

const TopMenuBar = (props: any) => {
    const ebook = useSelector((helloState: any) => {
        return helloState.ebookToggle
    })
    let textEvent = useSelector((state: any) => {
        return state.textToolBar
    })
    let inputPageSize = useSelector((state: any) => {
        return state.pageSize
    })
    let inputPageNumber = useSelector((state: any) => {
        return state.pageNumber
    })
    let inputBinding = useSelector((state: any) => {
        return state.binding
    })
    let value = useSelector((state: any) => {
        return state.value
    })
    let bleed = useSelector((state: any) => {
        return state.bleed
    })
    let canvasSize = useSelector((state: any) => {
        return state.canvasSize
    })
    const dimensions = useSelector((state: any) => {
        return state.dimensions
    })
    const prevdimensions = useSelector((state: any) => {
        return state.prevDimensions
    })
    const paddingDimensions = useSelector((state: any) => {
        return state.paddingDimensions
    })
    let canvas = new fabric.Canvas('canvas-element')

    let { selectedTextToolBar } = useSelector((state: any) => {
        return state
    })

    const [bgColor, setBgColor] = useState('#fff')
    const [anchorEl2, setAnchorEl2] = React.useState(null)
    const [anchorEl3, setAnchorEl3] = React.useState(null)
    const [anchorEl4, setAnchorEl4] = React.useState(null)

    const open2 = Boolean(anchorEl2)
    const open3 = Boolean(anchorEl3)
    const open4 = Boolean(anchorEl4)



    let dispatch = useDispatch()

    let pageDimension = constants.PAGE_DIMENTIONS


    useEffect(() => {
   
        const spineTitle = props.canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'spineTitle'
            })
        const spineAuthor = props.canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'spineAuthor'
            })

       

            props.canvas?.stage.on('object:modified',(object)=>{
              if(object.target.name==='book_title')
              spineTitle?.set({ text: object?.target.text })

             else if(object.target.name==='author')
             spineAuthor?.set({text:object?.target.text,scaleToX: object.target.scaleToX}) 
            })

       
    },[selectedTextToolBar])

    useEffect(() => {
        props.canvas?.stage.off('selection:created')
        props.canvas?.stage.on('selection:created', (e) => {
            dispatch({ type: 'ADD_CANVAS_EVENT', value: e })
            dispatch({
                type: 'SELECTED_OBJECT',
                payload: e.selected[0].toJSON(),
            })
        })

        props.canvas?.stage.off('selection:updated')
        props.canvas?.stage.on('selection:updated', (e) => {
            dispatch({ type: 'ADD_CANVAS_EVENT', value: e })
            dispatch({
                type: 'SELECTED_OBJECT',
                payload: e.selected[0].toJSON(),
            })
        })

        props.canvas?.stage.off('selection:cleared')
        props.canvas?.stage.on('selection:cleared', (e) => {
            dispatch({ type: 'ADD_CANVAS_EVENT', value: null })
        })

        //   if(props.canvas?.stage && paddingDimensions){
        //   const bookTitle=props.canvas?.stage.getObjects().find((item:any)=>{return item.name==='book_title'})

        //   let topoffsetratio1 = 0.2
        //  bookTitle?.set({
        //   'top':dimensions.height*topoffsetratio1,
        //   'left':paddingDimensions.frontpaddingleft.left,
        //   'width': paddingDimensions.frontpaddingright.left -paddingDimensions.frontpaddingleft.left,
        //   'area':'front',

        //  })
        //  bookTitle?.bringToFront();
        //    bookTitle?.setCoords();

        //  const subTitle=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='subtitle'})
        //  let topoffsetratio2 = 0.5
        //  subTitle?.set({
        //   'top':dimensions.height*topoffsetratio2,
        //   'left':paddingDimensions.frontpaddingleft.left,
        //   'width': paddingDimensions.frontpaddingright.left -paddingDimensions.frontpaddingleft.left,
        //   'area':'front',

        //  })
        // subTitle?.bringToFront();
        //  subTitle?.setCoords();
        //  const author=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='author'})
        //  let topoffsetratio3 = 0.8
        //  author?.set({
        //   'top':dimensions.height*topoffsetratio3,
        //   'left':paddingDimensions.frontpaddingleft.left,
        //   'width': paddingDimensions.frontpaddingright.left -paddingDimensions.frontpaddingleft.left,
        //   'area':'front',

        //  })
        //   author?.bringToFront();
        //  author?.setCoords();
        //   }

        //  let element=document.getElementById('canvas');
        //  let rect=element.getBoundingClientRect();
        //  setRect(rect)

        props.canvas?.stage.renderAll()

        // setupDimensions()
    }, [props.canvas])



    function processDownload(canvas) {
        let [width, height, spineWidth] = getCoverSize(
            pageDimension[inputPageSize][1],
            inputPageNumber,
            inputBinding
        )
        if (ebook) {
            width = (width - spineWidth) / 2
            spineWidth = 0
        }
        let dpi = 300
        let [printWidth, printHeight] = [width / dpi, height / dpi]

        //  setValue(null);
        //  dispatch({type:'SET_VALUE',payload:null})

        // props.canvas?.stage.requestRenderAll()
        let imgData = exportImageData(width, height, 1, canvas)

        // landscape or portrait?
        let mode = printWidth > printHeight ? 'l' : 'p'
        var pdf = new jsPDF(mode, 'in', [printWidth, printHeight])

        pdf.addImage(imgData, 'JPEG', 0, 0, printWidth, printHeight)
        let name = 'bookcover'

        pdf.save(name + '.pdf')
    }

    function exportImageData(width, height, zoom, cloneCanvas) {
        // hide borders and deselect selected element
        // hideBorders(cloneCanvas)
        cloneCanvas?.requestRenderAll()
        cloneCanvas?.discardActiveObject()

        // // resize canvas to actual size and zoom
        cloneCanvas?.setDimensions({ width: width, height: height })
        cloneCanvas?.setZoom(zoom)
        // capture canvas as image
        let imgData = cloneCanvas?.toDataURL('png')

        return imgData
    }

    function handleFront(event) {
        let value = event.target.value
        // setValue(value)
        dispatch({ type: 'SET_VALUE', payload: value })
    }

    function handleBack(event) {
        let value = event.target.value
        // setValue(value)
        dispatch({ type: 'SET_VALUE', payload: value })
    }

    function handleSpine(event) {
        let value = event.target.value
        // setValue(value)
        dispatch({ type: 'SET_VALUE', payload: value })
    }

    function handleDefault(event) {
        // setValue(null)
        dispatch({ type: 'SET_VALUE', payload: null })
    }

    useEffect(() => {
        if (value === 'front') {
            let front = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'frontShell'
            })
            front?.set({ stroke: 'red' })
            let frontPath = `M ${dimensions?.rightspine} 0 L ${dimensions?.width} 0 L ${dimensions?.width} ${dimensions?.height} L ${dimensions?.rightspine} ${dimensions?.height} z`
            let clipPath1 = new fabric.Path(frontPath, {
                absolutePositioned: true,
                originX: 'center',
                originY: 'center',
                fill: '',
                scaleX: 2,
                scaleY: 2,
                name: 'frontClipPath',
            })

            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (o.get('name') === 'text2') {
                    //  o.set({
                    //           clipPath:clipPath1,
                    //         });
                    o.bringToFront()
                    o.setCoords()
                }
            })

            props.canvas?.stage.renderAll()
        } else if (value !== 'front') {
            let front = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'frontShell'
            })
            front?.set({ stroke: null })

            props.canvas?.stage.renderAll()
        }
    }, [value, props.canvas, dimensions])

    useEffect(() => {
        if (value === 'back') {
            let back = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'backShell'
            })
            back?.set({ stroke: 'red', strokeWidth: 5 })
            // let backPath = 'M 0 0 L 1250 0 L 1250 1800 L 0 1800 z';
            let backPath = `M 0 0 L ${dimensions.leftspine / 2} 0 L ${
                dimensions.leftspine / 2
            } ${dimensions.height / 2} L 0 ${dimensions.height / 2}  z`
            let clipPath2 = new fabric.Path(backPath, {
                absolutePositioned: true,
                originX: 'center',
                originY: 'center',
                fill: '',
                scaleX: 2,
                scaleY: 2,
                name: 'backClipPath',
            })

            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (o.get('name') === 'text3') {
                    // o.set({
                    //   clipPath:clipPath2,
                    // });
                    o.bringToFront()
                    o.setCoords()
                    props.canvas?.stage.renderAll()
                }
            })
            props.canvas?.stage.renderAll()
        } else if (value !== 'back') {
            const back = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'backShell'
            })
            back?.set({ stroke: null, strokeWidth: 0 })
            props.canvas?.stage.renderAll()
        }
    }, [value, props.canvas?.stage, dimensions])

    useEffect(() => {
        if (value === 'spine') {
            let spine = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'spineShell'
            })
            spine?.set({ stroke: 'red', strokeWidth: 5 })

            var spinePath = `M ${dimensions.leftspine} 0 L ${dimensions.rightspine} 0 L ${dimensions.rightspine} ${dimensions.height} L ${dimensions.leftspine} ${dimensions.height} z`

            let clipPath3 = new fabric.Path(spinePath, {
                absolutePositioned: true,
                originX: 'center',
                originY: 'center',
                fill: '',
                scaleX: 1,
                scaleY: 2,
                name: 'spineClipPath',
            })

            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (o.get('name') === 'text4') {
                    o.set({
                        clipPath: clipPath3,
                    })

                    o.bringToFront()
                    o.setCoords()
                    props.canvas?.stage.renderAll()
                }
            })
            props.canvas?.stage.renderAll()
        } else if (value !== 'spine') {
            const spine = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'spineShell'
            })
            spine?.set({ stroke: null })
            // props.canvas?.stage.remove(spine)
            props.canvas?.stage.requestRenderAll()
        }
    }, [value, props.canvas, dimensions])

    useEffect(() => {
        if (value === null) {
            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (
                    o.get('name') === 'text' ||
                    o.get('name') === 'text2' ||
                    o.get('name') === 'text3' ||
                    o.get('name') === 'text4'
                ) {
                    o.bringToFront()
                    o.setCoords()
                    props.canvas?.stage.renderAll()
                }
            })
            let front = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'frontShell'
            })
            front?.set({ stroke: null })

            const spine = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'spineShell'
            })
            spine?.set({ stroke: null })

            const back = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'backShell'
            })
            back?.set({ stroke: null, strokeWidth: 0 })
            props.canvas?.stage.renderAll()
        }
    }, [value, props.canvas, dimensions])

    function handleText() {
        if (ebook) {
            let ebookText = props.canvas?.addText('Text', {
                fontFamily: 'Delicious_500',
                left: 200,
                top: 200,
                fill: 'black',
                fontSize: '100',
                name: 'text1',
            })
    
        } else if (value === 'front') {
            let frontText = props.canvas?.addText('Text', {
                fontFamily: 'Delicious_500',
                left: dimensions.rightspine + 100,
                top: 200,
                fill: 'black',
                fontSize: '150',
                name: 'text2',
            })

            let frontPath = `M ${dimensions.rightspine} 0 L ${dimensions.width} 0 L ${dimensions.width} ${dimensions.height} L ${dimensions.rightspine} ${dimensions.height} z`

            let clipPath1 = new fabric.Path(frontPath, {
                absolutePositioned: true,
                originX: 'center',
                originY: 'center',
                fill: '',
                scaleX: 2,
                scaleY: 2,
                name: 'frontClipPath',
            })

            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (o.get('name') === 'text2') {
                    //  o.set({
                    //           clipPath:clipPath1,
                    //         });
                   
                    o.bringToFront()
                    o.setCoords()
                    props.canvas?.stage.renderAll()
                }
            })
        } else if (value === 'back') {
            let backText = props.canvas?.addText('Text', {
                fontFamily: 'Delicious_500',
                left: 200,
                top: 200,
                fill: 'black',
                fontSize: '150',
                name: 'text3',
            })

            // let backPath = 'M 0 0 L 1250 0 L 1250 1800 L 0 1800 z';
            let backPath = `M 0 0 L ${dimensions.leftspine / 2} 0 L ${
                dimensions.leftspine / 2
            } ${dimensions.height / 2} L 0 ${dimensions.height / 2}  z`

            let clipPath2 = new fabric.Path(backPath, {
                absolutePositioned: true,
                originX: 'center',
                originY: 'center',
                fill: '',
                scaleX: 2,
                scaleY: 2,
                name: 'backClipPath',
            })

            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (o.get('name') === 'text3') {
                    // o.set({
                    //   clipPath:clipPath2,
                    // });
                   
                    o.bringToFront()
                    o.setCoords()
                    props.canvas?.stage.renderAll()
                }
            })
            props.canvas?.stage.renderAll()
        } else if (value === 'spine') {
            let spineText = props.canvas?.addText('Text', {
                fontFamily: 'Delicious_500',
                left: dimensions.rightspine,
                // width:dimensions.height,
                top: 100,

                fill: 'black',
                fontSize: '60',
                angle: '90',
                name: 'text4',
            })

            var spinePath = `M ${dimensions.leftspine} 0 L ${dimensions.rightspine} 0 L ${dimensions.rightspine} ${dimensions.height} L ${dimensions.leftspine} ${dimensions.height} z`

            let clipPath3 = new fabric.Path(spinePath, {
                absolutePositioned: true,
                originX: 'center',
                originY: 'center',
                fill: '',
                scaleX: 1,
                scaleY: 2,
                name: 'spineClipPath',
            })

            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (o.get('name') === 'text4') {
                    // o.set({
                    //   clipPath:clipPath3,
                    // });
                 
                    o.bringToFront()
                    o.setCoords()
                    props.canvas?.stage.renderAll()
                }
            })

            props.canvas.stage.requestRenderAll()
        } else {
            let full = props.canvas?.addText('Text', {
                fontFamily: 'Delicious_500',
                left: 200,
                top: 200,
                fill: 'black',
                fontSize: '100',
                name: 'text',
            })

            var objs = props.canvas?.stage.getObjects().filter(function (o) {
                if (o.get('name') === 'text') {
                
                    // o.bringToFront()
                    // while (o.textLines.length > 1) {
                    //   o.set({width: o.getScaledWidth() + 1})
                    // }
                    // o.calOffset()
                    //   o.setCoords()

                    props.canvas?.stage.renderAll()
                }
            })

            props.canvas?.stage.requestRenderAll()
        }
    }

    function handleBackgroundColor(updatedColor) {
        setBgColor(updatedColor.hex)
        if (value === 'front') {
            let shell1 = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'frontShell'
            })
            shell1.set({ fill: updatedColor.hex })
            props.canvas.stage.requestRenderAll()
            addToHistory(props.canvas?.stage)
            removeStates()
        } else if (value === 'back') {
            let shell2 = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'backShell'
            })
            shell2.set('fill', updatedColor.hex)
            props.canvas.stage.requestRenderAll()
            addToHistory(props.canvas?.stage)
            removeStates()
        } else if (value === 'spine') {
            let shell3 = props.canvas?.stage.getObjects().find((item: any) => {
                return item.name === 'spineShell'
            })
            shell3.set('fill', updatedColor.hex)
            props.canvas.stage.requestRenderAll()
            addToHistory(props.canvas?.stage)
            removeStates()
        } else {
            props.canvas?.stage.set('backgroundColor', updatedColor.hex)
            props?.canvas.stage?.requestRenderAll()
            addToHistory(props.canvas?.stage)
            removeStates()
        }
    }

    const handleFrontClip = () => {
        let activeObject = props.canvas?.stage.getActiveObject()

        let frontPath = `M ${dimensions.rightspine} 0 L ${dimensions.width} 0 L ${dimensions.width} ${dimensions.height} L ${dimensions.rightspine} ${dimensions.height} z`

        let clipPath1 = new fabric.Path(frontPath, {
            absolutePositioned: true,
            originX: 'center',
            originY: 'center',
            fill: '',
            scaleX: 2,
            scaleY: 2,
            name: 'frontClipPath',
        })

        activeObject.set({
            clipPath: clipPath1,
            left: dimensions.rightspine + 200,
            angle: '0',
        })
        activeObject.bringToFront()
        activeObject.setCoords()
        props.canvas?.stage.renderAll()
        dispatch({ type: 'SELECTED_OBJECT', payload: activeObject.toJSON() })
    }

    const handleBackClip = () => {
        let activeObject = props.canvas?.stage.getActiveObject()

        let backPath = `M 0 0 L ${dimensions.leftspine / 2} 0 L ${
            dimensions.leftspine / 2
        } ${dimensions.height / 2} L 0 ${dimensions.height / 2}  z`

        let clipPath2 = new fabric.Path(backPath, {
            absolutePositioned: true,
            originX: 'center',
            originY: 'center',
            fill: '',
            scaleX: 2,
            scaleY: 2,
            name: 'backClipPath',
        })

        activeObject.set({
            clipPath: clipPath2,
            left: 200,
            angle: '0',
        })
        activeObject.bringToFront()
        activeObject.setCoords()
        props.canvas?.stage.renderAll()

        props.canvas?.stage.renderAll()
    }

    const handleSpineClip = () => {
        let activeObject = props.canvas?.stage.getActiveObject()

        var spinePath = `M ${dimensions.leftspine} 0 L ${dimensions.rightspine} 0 L ${dimensions.rightspine} ${dimensions.height} L ${dimensions.leftspine} ${dimensions.height} z`

        let clipPath3 = new fabric.Path(spinePath, {
            absolutePositioned: true,
            originX: 'center',
            originY: 'center',
            fill: '',
            scaleX: 1,
            scaleY: 2,
            name: 'spineClipPath',
        })

        activeObject.set({
            clipPath: clipPath3,
            left: dimensions.rightspine ? dimensions.rightspine : 0,
            top: 100,
            fontSize: '60',
            angle: '90',
        })

        activeObject.bringToFront()
        activeObject.setCoords()
        props.canvas?.stage.renderAll()
    }

    const handleRemoveClip = () => {
        let activeObject = props.canvas?.stage.getActiveObject()

        activeObject.set({
            clipPath: null,
        })
        activeObject.bringToFront()
        activeObject.setCoords()
        props.canvas?.stage.renderAll()
        addToHistory(props.canvas?.stage)
        removeStates()
    }

    const handleDeleteButton = () => {
        let activeObject = props.canvas?.stage.getActiveObject()
        props.canvas?.stage.remove(activeObject)
    }
    props.canvas?.stage.requestRenderAll()
    addToHistory(props.canvas?.stage)

    const [enabled, setEnabled] = useState(true)
    const [initialStep, setInitialStep] = useState(0)

    const onExit = () => {
        setEnabled(false)
    }
    const steps = [
        {
            element: '#selectOption',
            intro: 'You can use these options to select a specific side of the cover',
        },
        {
            element: '#fabook',
            intro: 'You can use this button to change the book specification',
        },

        {
            element: '#isbn',
            intro: 'You can use this button to generate barcode from your registered ISBN ',
        },
        {
            element: '#addImage',
            intro: 'You can use this button to add the Image',
        },
        {
            element: '#imageToolbar',
            intro: 'You can use this button to bring the image to front , send it to back, bring forward else send the image backward',
        },
        {
            element: '#colourpicker',
            intro: 'You can use this button to select the color for the background or the selected side.',
        },
        {
            element: '#addText',
            intro: 'You can use this button to add text.',
        },
        {
            element: '#download',
            intro: 'You can use this button to download your designed cover',
        },

        // {
        //   element: '#fitTo',
        //   intro: 'You can use this button to fit the image on the front,back,spine or on the entire cover.',
        // },

        {
            element: '#delete',
            intro: 'You can use this button to delete the selected object',
        },
        {
            element: '#zoombar',
            intro: 'This is your zoom bar, you can use these buttons to zoom in and zoom out your canvas',
        },
        {
            element: '#grid',
            intro: 'You can use this option to snap the objects to the grid',
        },
        {
            element: '#canvas',
            intro: 'This is your canvas where you can design your cover',
        },
    ]

    const steps2 = [
        {
            element: '#fabook',
            intro: 'You can use this button to change the book specification',
        },

        {
            element: '#isbn',
            intro: 'You can use this button to generate barcode from your registered ISBN ',
        },
        {
            element: '#addImage',
            intro: 'You can use this button to add the Image',
        },
        {
            element: '#imageToolbar',
            intro: 'You can use this button to bring the image to front , send it to back, bring forward else send the image backward',
        },
        {
            element: '#colourpicker',
            intro: 'You can use this button to select the color for the background or the selected side.',
        },
        {
            element: '#addText',
            intro: 'You can use this button to add text.',
        },
        {
            element: '#download',
            intro: 'You can use this button to download your designed cover',
        },

        // {
        //   element: '#fitTo',
        //   intro: 'You can use this button to fit the image on the front,back,spine or on the entire cover.',
        // },

        {
            element: '#delete',
            intro: 'You can use this button to delete the selected object',
        },
        {
            element: '#zoombar',
            intro: 'This is your zoom bar, you can use these buttons to zoom in and zoom out your canvas',
        },
        {
            element: '#grid',
            intro: 'You can use this option to snap the objects to the grid',
        },
        {
            element: '#canvas',
            intro: 'This is your canvas where you can design your cover',
        },
    ]

    // useEffect(()=>{
    //     document.addEventListener('DOMContentLoaded', (event) => {    
    //         var myModal = new bootstrap.Modal(document.getElementById("#Instructions"));
    //         myModal.show();
    //       });
    // },[])

 
 
    return (
        <div className="px-4 pt-2 bg-light"  >
            <div className="row">
                <nav className='navbar navbar-expand-lg '>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>   
                  
       
                <div className=" col-sm-2 col-md-4 col-lg-2 navbar-brand"  >
                    <a className="mr-4" href="#">
                        <img
                            style={{ width: '250px', height: '45px' }}
                            src={masterblockslogo}
                        />
                    </a>
                </div>
                <div className='collapse navbar-collapse'  id="navbarTogglerDemo02"> 
                <ul className="navbar-nav col-sm-8 col-md-8 col-lg-4 mx-lg-5"  >
                    {/* <button  className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                          <span  className="navbar-toggler-icon"></span>
             </button> */}
                    <li className='nav-item'>
                   
                        <button

                            title="Book Specification"
                            id="fabook"
                            className="btn btn-default"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#bookspecs"
                        >
                            <FontAwesomeIcon icon={faBook} />
                        </button>
                    
                    <button
                        title="ISBN Barcode Generator"
                        id="isbn"
                        className="btn btn-default"
                    >
                      
                            <ISBNMenu canvas={props.canvas} />
                   
                    </button>
                
                    
                        <button
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add Text"
                            id="addText"
                            className="btn btn-default"
                            onClick={() => {
                                handleText()
                            }}
                        >
                            <FontAwesomeIcon icon={faFont} />
                        </button>
                 
                
                  
                   
                        <AddImage canvas={props.canvas} />
                  
                
                    <Popover
                        anchorEl={anchorEl2}
                        open={open2}
                        id={open2 ? 'simple-popover' : undefined}
                        onClose={() => {
                            setAnchorEl2(null)
                        }}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'top',
                        }}
                        anchorOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                    >
                        <ChromePicker
                            color={bgColor}
                            onChange={handleBackgroundColor}
                            disableAlpha={true}
                        />
                    </Popover>
                  
                 
                        <button
                            title="Change Backgroundcolor"
                            id="colourpicker"
                            className="btn btn-default"
                            onClick={(event) => {
                                setAnchorEl2(event.currentTarget)
                            }}
                        >
                            {' '}
                            <FontAwesomeIcon
                                color={
                                    bgColor != '#fff' &&
                                    bgColor != '#ffffff' &&
                                    bgColor.toLocaleLowerCase() != 'white'
                                        ? bgColor
                                        : '#000'
                                }
                                icon={faBrush}
                            />
                        </button>
                  
                    </li>
                    `
                    {typeof textEvent?.selected != 'undefined' ? (
                        <>
                     
                        <li className='nav-item'>    
                                <button
                                    title="Set Opacity"
                                    id="opacitySlider"
                                    className="btn btn-default"
                                    onClick={(event) => {
                                        setAnchorEl3(event.currentTarget)
                                    }}
                                >
                                    {' '}
                                    <FontAwesomeIcon icon={faEye} />
                                </button>
                         
                            <Popover
                                anchorEl={anchorEl3}
                                open={open3}
                                id={open3 ? 'simple-popover' : undefined}
                                onClose={() => {
                                    setAnchorEl3(null)
                                }}
                                transformOrigin={{
                                    horizontal: 'center',
                                    vertical: 'top',
                                }}
                                anchorOrigin={{
                                    horizontal: 'center',
                                    vertical: 'bottom',
                                }}
                            >
                                 
                                <div className="mx-3 my-3 nav-item">
                               
                                    <label
                                        htmlFor="customRange1"
                                        className="form-label px-4 py-2 "
                                    >
                                        Opacity:
                                    </label>
                                    <input
                                        className="mx-3"
                                        onChange={(event) => {
                                            let ovalue =
                                                parseInt(event.target.value) /
                                                100
                                            let activeObject =
                                                props.canvas?.stage.getActiveObject()
                                            if (activeObject)
                                                activeObject.set({
                                                    opacity: ovalue,
                                                })
                                            props.canvas.stage.requestRenderAll()
                                            dispatch({
                                                type: 'SELECTED_OBJECT',
                                                payload: activeObject.toJSON(),
                                            })
                                        }}
                                        min="0"
                                        max="100"
                                        value={Math.round(
                                            selectedTextToolBar.opacity * 100
                                        ).toString()}
                                        type="range"
                                        className="form-range"
                                        id="customRange1"
                                    ></input>
                                </div>
                            </Popover>

                            <span className="nav-item">
                                <ImageToolbar canvas={props.canvas} />
                            </span>
                         
                                <button
                                    title="Clip To"
                                    id="clipPath"
                                    className="btn btn-default"
                                    onClick={(event) => {
                                        setAnchorEl4(event.currentTarget)
                                    }}
                                >
                                    {' '}
                                    <FontAwesomeIcon icon={faPaperclip} />
                                </button>
                       
                            {textEvent?.selected[0].type === 'image' ? (
                                <span className="">
                                    <BackgroundImage
                                        canvas={props.canvas}
                                        value={value}
                                        pixabay={textEvent.selected[0]}
                                    />{' '}
                                </span>
                            ) : null}
                        

                            <button
                                id="delete"
                                title="Delete"
                                onClick={handleDeleteButton}
                                className="btn btn-default"
                            >
                                <FontAwesomeIcon icon={faTrashCan} />
                            </button>
                            </li>
                        </>
                    ) : null}
                  
                    {!ebook && typeof textEvent?.selected != 'undefined' ? (
                        <>
                            <Popover
                                anchorEl={anchorEl4}
                                open={open4}
                                id={open4 ? 'simple-popover' : undefined}
                                onClose={() => {
                                    setAnchorEl4(null)
                                }}
                                transformOrigin={{
                                    horizontal: 'center',
                                    vertical: 'top',
                                }}
                                anchorOrigin={{
                                    horizontal: 'center',
                                    vertical: 'bottom',
                                }}
                            >
                                <div
                                    style={{
                                        padding: '5px',
                                        width: '190px',
                                        height: '260px',
                                        color: 'grey',
                                    }}
                                >
                                    <div
                                        className="my-2 px-4 py-1 my-3"
                                        style={{
                                            color: 'black',
                                            borderBottom: '1px solid grey',
                                        }}
                                    >
                                        <b> Clipto </b>
                                    </div>
                                    <div
                                        className="my-2 px-4 py-1 my-3 hoverItem"
                                        onClick={handleFrontClip}
                                    >
                                        {' '}
                                        Front{' '}
                                    </div>
                                    <div
                                        className="my-2 px-4 py-1 my-3 hoverItem"
                                        onClick={handleBackClip}
                                    >
                                        {' '}
                                        Back{' '}
                                    </div>
                                    <div
                                        className="my-2 px-4 py-1 my-3 hoverItem"
                                        onClick={handleSpineClip}
                                    >
                                        {' '}
                                        Spine{' '}
                                    </div>
                                    <div
                                        className="my-2 px-4 py-1 my-3 hoverItem"
                                        onClick={handleRemoveClip}
                                    >
                                        {' '}
                                        Remove{' '}
                                    </div>
                                </div>
                            </Popover>
                        </>
                    ) : null}
                </ul>
                                   
                <ul className="navbar-nav col-sm-4 col-md-8 col-lg-3 py-3 ">
                    {!ebook ? (
                        <div className="">
                          
                            <span id="selectOption" className="">
                                <ul className="navbar-nav px-2">
                                    <li className='nav-item'>
                                <label title="Back Cover">
                                    <input
                                        name="cover"
                                        type="radio"
                                        onClick={handleBack}
                                        checked={value === 'back'}
                                        value="back"
                                    />{' '}
                                  
                                    <span
                                        className="px-2"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Back
                                    </span>
                                </label>
                                
                                
                             
                                <label title="Spine Cover">
                                    <input
                                        name="cover"
                                        type="radio"
                                        onClick={handleSpine}
                                        checked={value === 'spine'}
                                        value="spine"
                                    />{' '}
                                    <span
                                        className="px-2"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Spine{' '}
                                    </span>
                                </label>
                              
                            
                                <label title="Front Cover">
                                    <input
                                        name="cover"
                                        type="radio"
                                        onClick={handleFront}
                                        checked={value === 'front'}
                                        value="front"
                                    />{' '}
                                    <span
                                        className="px-2"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Front{' '}
                                    </span>
                                </label>
                              
                                <label>
                                    <input
                                        name="cover"
                                        type="radio"
                                        onClick={handleDefault}
                                        checked={value === null}
                                    />{' '}
                                    <span
                                        className="px-2"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Full Cover
                                    </span>
                                </label>
                                </li>
                                </ul>
                            </span>
                        </div>
                       
                    ) : null}
         
                 </ul> 
              
                 <div className="col-lg-3 px-lg-2 offset-lg-2 ">
                    {props.canvas ? (
                        
              <DownloadPDF canvas={props.canvas.stage} />
                       
                    ) : null}
              
              <UndoRedo canvas={props.canvas} />

                     
                   
                    {/* <Steps
        enabled={enabled}
        steps={ebook?steps2:steps}
        initialStep={initialStep}
        onExit={onExit}
     /> */}
                    {/* <button title="info" onClick={()=>{setEnabled(!enabled)} } className='nav-item btn btn-outline-dark mx-3' ><FontAwesomeIcon  icon={faCircleInfo}/></button> */}
                </div>
              
                </div>
                
                </nav>
            </div>
            <div className="row justify-content-center">
                     
                      
                      
                       
                        <div className="nav-item col-lg-4 col-md-4 col-sm-12">
                            {textEvent?.selected[0].text ? (
                                <span
                                    className=""
                                    style={{
                                        borderColor: 'transparent',
                                        backgroundColor: '',
                                        
                                    }}
                                >
                                    {' '}
                                    <TextToolBar canvas={props.canvas} />
                                </span>
                            ) : null}
                        </div>
                        
              
            </div>
        </div>
    )
}

export default TopMenuBar
