import React,{useEffect,useState,useRef} from 'react'
import Ruler from "@scena/react-ruler";
import { useSelector,useDispatch } from 'react-redux'
const Scale = (props:any) => {
    const [ruler,setRuler]=useState(null)
    const [rulerLeft,setRulerLeft]=useState(0)
    const [rulerTop,setRulerTop]=useState(0)
    const rulerLeftPointer = useRef<HTMLDivElement | null>(null)
    const rulerTopPointer = useRef<HTMLDivElement | null>(null)
    const scale=useSelector((state:any)=>{return state.scale})
    let canvasSize=useSelector((state:any)=>{return state.canvasSize})
    const dimensions=useSelector((state:any)=>{return state.dimensions})
    const dispatch=useDispatch();

const [wrapper,setWrapper]=useState(null)
    let leftp=0;
    let topp=0;
    const [rect,setRect]=useState(null)



    useEffect(()=>{
        let wraps=document.getElementById('wrapper-canvas').getBoundingClientRect()
        setWrapper(wraps)
    },[props.canvas])
    useEffect(()=>{
        ruler?.resize();
      
        window.addEventListener("resize", () => {
            ruler?.resize();
            let element=document.getElementById('canvas');
            let rect=element?.getBoundingClientRect();
            setRect(rect)

        })
        let element=document.getElementById('canvas');
        let rect=element?.getBoundingClientRect();
         setRect(rect)
        

      },[props.canvas?.stage,canvasSize.canvasWidth,canvasSize.canvasHeight,wrapper])


      

// const getUnit = (): number => {
//     const zoomLevel = getZoomLevel()
  
  
//     return 37.7952 * props.canvas?.aspectRatio * zoomLevel
        
//   }
  
//   const getZoomLevel = (): number => {
//     if (props.canvas?.zoomLevel === 'fit' || props.canvas?.zoomLevel === 'fill') {
//         return 100
//     }
//     return props.canvas?.zoomLevel
//   }
  
//   const formatFtIn = (num: number): string => {
//     if (!num) return '0'
//     const zoomLevel = getZoomLevel()
//     const sizeUnit = 'in'
  
//     const unit = sizeUnit === 'in' ? '"' : 'ft'
//     let divider = 96
//     if (unit !== 'ft') {
//         divider = 96 / 12
//     }
  
//     return (
//         (props.canvas?.aspectRatio
//             ? num / divider / (props.canvas?.aspectRatio * zoomLevel * 0.01)
//             : num / divider
//         ).toFixed(0) + unit
//     )
//   }

  const [visible,setVisibility]=useState(false)

  useEffect(()=>{
      setTimeout(()=>{
        setVisibility(true)  
      },(300))
    
  },[props.canvas])


  useEffect(() => {
    // if (!(props.canvas?.stage)) {

        props.canvas?.stage?.off('mouse:out')
        props.canvas?.stage?.on('mouse:out', (e: any) => {
         
  
            if (rulerLeftPointer?.current)
                rulerLeftPointer.current.style.visibility = 'hidden'
            if (rulerTopPointer?.current)
                rulerTopPointer.current.style.visibility = 'hidden'
        })
  
        props.canvas?.stage?.off('mouse:move')
        props.canvas?.stage?.on('mouse:move', (e: any) => {
            if (rulerLeftPointer?.current)
                rulerLeftPointer.current.style.visibility = 'visible'
            if (rulerTopPointer?.current)
                rulerTopPointer.current.style.visibility = 'visible'
               
                setRulerTop(e.pointer.y)
            
                setRulerLeft(e.pointer.x)
            
        })
    // }
  }, [props.canvas.stage])

  return (
    <div style={{visibility:visible?"visible":"hidden"}}>

<div className='wrapper'>
                                {canvasSize?<Ruler
                                    // ref={(e)=>{setRuler(e)}}
                                    type="horizontal"
                                    backgroundColor="white"
                                    textColor="#707070"
                                    lineColor="#707070"
                                    direction="end"
                                    mainLineSize="100%"
                                    longLineSize={10}
                                    shortLineSize={5}
                                    unit={1}
                                    zoom={300*props.canvas?.stage?.getZoom()}
                                    segment={10}
                                    style={{
                                        display: 'flex',
                                        height: '30px',
                                        left:rect?.x,
                                        position:'absolute',
                                        width:canvasSize?.canvasWidth,
                                        top:rect?.y-30,

                                        
                                    }}
                                    textFormat={(num:number)=>{
                                      const unit =  '"'                               
                                      return (
                                         num  + unit
                                      )
                                    }}
                                />:null}
                                {props.canvas?.stage?<div
                                    ref={rulerTopPointer}
                                    style={{
                                        left:rulerLeft+12,
                                        top:-(canvasSize.canvasHeight/2+11),
                                        borderLeft: '1px solid #333f53',
                                        height: '25px',
                                        position:'relative',
                                        zIndex:2
                                    }}
                                ></div>:null}
                          </div>
                            <div>
                               
                                {rect?<Ruler 
                                    type="vertical"
                                    unit={1}
                                    zoom={300*props.canvas?.stage?.getZoom()}   
                                    backgroundColor="white"
                                    textColor="#707070"
                                    lineColor="#707070"
                                    direction="end"
                                    mainLineSize="100%"
                                    longLineSize={10}
                                    shortLineSize={5}
                                    segment={10}
                              
                                    style={{
                                        display: 'flex',
                                        left:rect?.x-25,
                                        height:canvasSize?.canvasHeight,
                                        position:'absolute',
                                        top:rect?.y,
                                        width:'25px',
                                        padding:'2px',
                                    }}

                                    textFormat={(num:number)=>{
                                      const unit =  '"'                               
                                      return (
                                         num  + unit
                                      )
                                    }}
                                />:null}
                                {props.canvas.stage?<div
                                    ref={rulerLeftPointer}
                                    style={{
                                        borderTop: '1px solid #333f53',
                                        top:rulerTop-8-(canvasSize.canvasHeight/2),
                                    
                                        position: 'relative',
                                        width:24,
                                        zIndex:2
                                    }}
                                ></div>:null}
                            </div>
    </div>
  )
}

export default Scale

export interface RulerInterface {
    scroll(scrollPos: number): any;
    resize(): any;
  }
  export interface RulerProps {
    type?: "horizontal" | "vertical";
    width?: number;
    height?: number;
    unit?: number;
    zoom?: number;
    direction?: "start" | "end";
    style?: IObject<any>;
    backgroundColor?: string;
    lineColor?: string;
    textColor?: string;
    textFormat?: (scale: number) => string;
  }