import { PROPS_TO_INCLUDE_IN_CANVAS } from "../constants.tsx";

let CANVAS_STATES = [];
let CANVAS_STATE_INDEX = 0;

const addToHistory = async (newCan) => {


  CANVAS_STATES.push(newCan?.toJSON(PROPS_TO_INCLUDE_IN_CANVAS));
  CANVAS_STATE_INDEX = CANVAS_STATES.length - 1;
 
};


    const addStateIndex = ()=>{

        if(CANVAS_STATE_INDEX>1)
        {
            CANVAS_STATE_INDEX = CANVAS_STATE_INDEX-1
          
        }
       
        return CANVAS_STATE_INDEX
    }



const removeStateIndex=()=>{

    if(CANVAS_STATE_INDEX<=CANVAS_STATES.length-1)
    {
        CANVAS_STATE_INDEX = CANVAS_STATE_INDEX+1;
    }
 

    return CANVAS_STATE_INDEX 
}

const resetHistory=()=>{
  while(CANVAS_STATES.length>0) 
  {
         CANVAS_STATES.pop()
  }
}

const removeStates=()=>{
  CANVAS_STATES=CANVAS_STATES.filter((ele,index)=>{
     return index <=CANVAS_STATE_INDEX
    })
}

export {CANVAS_STATES,CANVAS_STATE_INDEX, addToHistory, addStateIndex,removeStateIndex,resetHistory,removeStates}