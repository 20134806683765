import { ActiveSelection } from 'fabric/fabric-impl';
import * as constants from '../../constants.tsx'
const initialState=false;


const ebookToggle:any=(state=initialState,action:any)=>{
    switch(action.type){
        case "TOGGLE_EBOOK":return state=!state;
        case "SET_EBOOK":return state=action.payload
        default:return state;
    }
}

export const pageSize:any=(state='5x7',action:any)=>{
    switch(action.type){
        case "CHANGE_PAGE_SIZE":return state=action.pageSize
        default: return state;
    }
}

export const pageNumber:any=(state=200,action:any)=>{
    switch(action.type){
        case 'CHANGE_PAGE_NUMBER':return parseInt(action.value)
        default: return state

    }
}

export const binding:any=(state='SP',action:any)=>{
    switch(action.type){
        case 'CHANGE_BINDING':
            return action.value
        default:return state
    }
}

const noBleed = [0,0]
const defaultBleed = [constants.WIDTH_BLEED * constants.DPI, constants.HEIGHT_BLEED * constants.DPI]
const hcBleed = [
  (constants.WIDTH_BLEED + constants.FLAP) * constants.DPI,
  (constants.HEIGHT_BLEED + constants.FLAP) * constants.DPI]


export const bleed = (state=defaultBleed , action:any) => {
    switch (action.type) {
      case 'BLEED_EBOOK':
      return action.value ? state=noBleed : state=defaultBleed;
      case 'CHANGE_BINDING':
        return action.value==='HC'? state=hcBleed:state=defaultBleed;

      default:
        return state
    }
  }

  export const canvasimage = (state = null, action:any) => {
    switch (action.type) {
      case 'IMAGE_UPLOAD':
        return action.file ? action.file : null
      default:
        return state
    }
}

    export const textToolBar=(state=null,action:any)=>{
        switch(action.type){
            case 'ADD_CANVAS_EVENT':
                return state=action.value;
            default: return state;
        }
    }

    
  
    export const selectedTextToolBar=(state={
     fontSize:null,
     color:null,
     fontFamily:null,
     textAlign:null,
     opacity:null,
     charSpacing:null,
     bold:null,
     italic:null,
     linethrough:null
    },action:any)=>{
        switch(action.type){
            case 'SELECTED_OBJECT':

            return {...state,...action.payload}
        
        default:return state;
            }
    }


    export const dimensions:any=(state={
     leftspine:null,
     rightspine:null, 
     height:null,
     width:null,
     spinewidth:null
 },action:any)=>{
    switch(action.type){
        case 'SET_DIMENSIONS':
            return {...state,leftspine:action.leftspine,rightspine:action.rightspine,spinewidth:action.spinewidth,width:action.width,height:action.height}
            default: return state;
    }

 }

 export const coverToggle:any=(state=false,action:any)=>{
     switch(action.type){
         case 'CANVAS_TOGGLE':
             return state=!state;
         default:return state;    
     }
 }

 export const updateIsbn:any=(state=null,action:any)=>{
     switch(action.type){
         case 'UPDATE_ISBN':
             return state=action.payload;
             default:return state;
     }
 }

 export const canvasSize:any=(state={canvasHeight:null,canvasWidth:null,rect:null},action:any)=>{
     switch(action.type){
         case 'UPDATE_CANVAS':
             return {...state,canvasHeight:action.height,canvasWidth:action.width,rect:action.rect}
             default:return state;
     }
 }

 export const scaleZoom:any=(state=1,action:any)=>{
switch(action.type){
    case 'INCREASE_ZOOM':
        return state=state*1.1;
    case 'DECREASE_ZOOM':
        return state=state/1.1;    
        default:return state;
}
 }

 export const prevDimensions:any=(state={
    leftspine:null,
    rightspine:null, 
    height:null,
    width:null,
    spinewidth:null
},action:any)=>{
    switch(action.type){
        case 'SET_PREVDIMENSIONS':
            return {...state,leftspine:action.leftspine,rightspine:action.rightspine,spinewidth:action.spinewidth,width:action.width,height:action.height}
            default: return state;
    }
  
 }

 export const paddingDimensions:any=(state={
    frontpaddingleft:null, 
    frontpaddingright:null, 
    backpaddingleft:null,
    backpaddingright:null

},action:any)=>{
    switch(action.type){
        case 'SET_PADDINGDIMENSIONS':
            return {...state,frontpaddingleft:action.frontpaddingleft,frontpaddingright:action.frontpaddingright,backpaddingleft:action.backpaddingleft,backpaddingright:action.backpaddingright}
            default: return state;
    }
  
 }

 export const value:any=(state=null,action:any)=>{
    switch(action.type){
        case 'SET_VALUE':
            return state=action.payload
            default:return state;
    }
} 

export const finalDimension:any=(state={
pageSize:'5x7',
pageNumber:200,
binding:'SP',
toggleEbook:false
},action:any)=>{
    switch(action.type){
case 'SET_FINAL_DIMENSIONS':
    return {...state,pageSize:action.pageSize,pageNumber:action.pageNumber,binding:action.binding,toggleEbook:action.toggleEbook}
default: return state;    
    }
}

export const doneButton:any=(state=false,action)=>{
    switch(action.type){
        case 'SET_DONE':
            return state=!state
            default:return state;
    }
}

export const scale:any=(state=null,action)=>{
    switch(action.type){
        case 'SET_SCALE':
            return state=action.payload ;
            default:return state;
    }
}

export default ebookToggle;