import ebookToggle,{pageSize,pageNumber,binding,bleed,canvasimage,textToolBar,selectedTextToolBar,dimensions,coverToggle,updateIsbn,canvasSize,scaleZoom,prevDimensions,value,paddingDimensions,finalDimension,doneButton,scale} from './textReducer.tsx'
import { combineReducers } from 'redux';

const rootReducer=combineReducers({
    ebookToggle,
    pageSize,
    pageNumber,
    binding,
    bleed,
    canvasimage,
    textToolBar,
    selectedTextToolBar,
    dimensions,
    coverToggle,
    updateIsbn,
    canvasSize,
    scaleZoom,
    prevDimensions,
    value,
    paddingDimensions,
    finalDimension,
    doneButton,
    scale
})
export default rootReducer;