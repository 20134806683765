import * as constants from '../constants.tsx'
import ISBN from 'isbn-utils'


export function getSpineWidth (numPages:any, binding:any) {


  

    let spineWidth = 0
    switch (binding) {
      case 'SP':
        spineWidth = constants.SPINE_PER_PAGE * numPages
        break
      case 'HC':
        spineWidth = constants.SPINE_PER_PAGE * numPages + constants.EXTRA_SPINE
        break
      default:
        spineWidth = 0
    }
    return spineWidth
  
  }


export function getCoverSize (pagesize:any, pages:any, binding:any,
  extraWidth = constants.WIDTH_BLEED, extraHeight = constants.HEIGHT_BLEED) {
    
  let flap = 0
  if (binding === 'HC') {
    flap = constants.FLAP
    extraWidth = constants.EXTRA_WIDTH
    extraHeight = constants.EXTRA_HEIGHT
  }
  let spineWidth = getSpineWidth(pages, binding)
  let coverWidth = 2 * pagesize[0] + spineWidth + 2 * extraWidth + 2 * flap
  let coverHeight = pagesize[1] + 2 * extraHeight + 2 * flap
  return [Math.ceil(coverWidth * constants.DPI), Math.ceil(coverHeight * constants.DPI), Math.ceil(spineWidth * constants.DPI)]
}

// export function getPageDimensions (size) {
//     return window.coverCreator.pageSizeDimensions[size][1]
//   }
  
// Borrowed from https://davidwalsh.name/query-string-javascript
export function getUrlParameter (name:any) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
};

export function validateISBN (isbn:any) {
  isbn = isbn?.toUpperCase()
  return ISBN.parse(isbn?.replace(/ISBN|-| /g, ''))
}