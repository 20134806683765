import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { fabric } from "fabric";
import { PIXABAY_BASE_URL } from "../constants.tsx";
import { PEXELS_BASE_URL } from "../constants.tsx";


import Masonry from "masonry-layout";
import "../components/styles/style.scss";
import imagesLoaded from "imagesloaded";
import { addToHistory, removeStates } from "./CanvasHistory.tsx";

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const Pixabay = (props) => {
  const [apiValue, setApiValue] = useState(true);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  let word = null;

  const Masonry = require("masonry-layout");
  const imgloaded = require("imagesloaded");
  const elem = document.querySelector(".grid");
  const container = document.querySelector("#images");
  const value = useSelector((state: any) => {
    return state.value
})
const dimensions = useSelector((state: any) => {
  return state.dimensions
})

const paddingDimensions = useSelector((state: any) => {
  return state.paddingDimensions
})

const [post,setPost]=useState(false)

  setTimeout(() => {
    var imgLoad = imgloaded(container);
    function onAlways() {
      const msnry = new Masonry(elem, {
        // options
        itemSelector: ".grid-item",
        gutter: 10,
      });
      // console.log('all images are loaded')
    }
    if (elem) {
      // bind with .on()
      imgLoad.on("always", onAlways);
      // unbind with .off()
      // imgLoad.off( 'always', onAlways )
    }
  }, 1000);

  const loadImages2 = async (search: string) => {
    setPost(true)
    let api = `${PEXELS_BASE_URL}${search}&per_page=20&page=1`;
    let file = await axios
      .get(api, {
        headers: {
          Authorization:
            "563492ad6f917000010000016f79a101e9df4b3fab936182571669fa",
        },
      })
      .then((data) => data);
    setData2(file.data.photos);
    setPost(false)
  };

  const loadImages = async (search: string) => {
    setPost(true)
    let api = `${PIXABAY_BASE_URL}${search}&image_type=photo&per_page=20`;
    let data = await axios.get(api).then((data) => data);
    setData(data.data.hits);
    setPost(false)
  };

  useEffect(() => {
    if (apiValue === true) {
      loadImages("nature");
    } else {
      loadImages2("nature");
    }
  }, []);

  useEffect(() => {
    if (search && search?.length > 1) {
      if (apiValue === true) {
        loadImages(search);
      } else if(apiValue===false) {
        loadImages2(search);
      }
    } else {
        if(apiValue===true)
      loadImages("nature");
      else
      loadImages2("nature")
    }
  }, [search, apiValue]);

  function searchKeyword() {
    setSearch(word);
  }
  const finalChangeKeyword = debounce(searchKeyword, 2000);



 const handleData=(item)=>{

  if(value==="front"){
       
    props.canvas?.addImage(item?.largeImageURL, {
      name: "pixabay",
      left: paddingDimensions.frontpaddingleft.left,
      top: dimensions.height*1/3,
    });
    props.canvas?.stage.requestRenderAll();
    props.setAnchorEl(null)
   addToHistory(props.canvas?.stage)
   removeStates()
  }
  else if (value==="back"){
       
    props.canvas?.addImage(item?.largeImageURL, {
      name: "pixabay",
      left:  paddingDimensions?.backpaddingleft.left,
      top: dimensions.height*1/3,
    });
    props.canvas?.stage.requestRenderAll();
    props.setAnchorEl(null)
    addToHistory(props.canvas?.stage)
    removeStates()
  }
  else if (value==="spine"){
    props.canvas?.addImage(item?.largeImageURL, {
      name: "pixabay",
      left:(dimensions.width/2)-(item.width/2),
      top: 100,
    });
    props.canvas.stage.requestRenderAll();
    props.setAnchorEl(null)
    addToHistory(props.canvas?.stage)
    removeStates()
  }

  else if (value===null)
  {
     
    props.canvas?.addImage(item?.largeImageURL, {
      name: "pixabay",
      left: paddingDimensions.backpaddingleft.left+dimensions.spinewidth,
      top: dimensions.height*1/3,
    });
    props.canvas.stage.requestRenderAll();
    props.setAnchorEl(null)
    addToHistory(props.canvas?.stage)
    removeStates()
  }

  props.canvas.stage.requestRenderAll();
  props.setAnchorEl(null)
 }


 const handleData2=(item)=>{
  if(value==="front"){
       
    props.canvas?.addImage(item?.src.large2x, {
      name: "pexels",
      left: paddingDimensions.frontpaddingleft.left,
      top: dimensions.height*1/3,
    });
    props.canvas.stage.requestRenderAll();
    props.setAnchorEl(null)
    addToHistory(props.canvas?.stage)
    removeStates()
  }
  else if (value==="back"){
       
    props.canvas?.addImage(item?.src.large2x, {
      name: "pexels",
      left: paddingDimensions.backpaddingleft.left,
      top: dimensions.height*1/3,
    });
    props.canvas.stage.requestRenderAll();
    props.setAnchorEl(null)
    addToHistory(props.canvas?.stage)
    removeStates()
  }
  else if (value==="spine"){
     
    props.canvas?.addImage(item?.src.large2x, {
      name: "pexels",
      left: (dimensions.width/2)-(item.width/2),
      top: 100,
    });
    props.canvas.stage.requestRenderAll();
    props.setAnchorEl(null)
    addToHistory(props.canvas?.stage)
    removeStates()
  }

  else if (value===null)
  {
     
    props.canvas?.addImage(item?.src.large2x, {
      name: "pexels",
      left: paddingDimensions.backpaddingleft.left+dimensions.spinewidth,
      top: dimensions.height*1/3,
    });
    props.canvas.stage.requestRenderAll();
    props.setAnchorEl(null)
    addToHistory(props.canvas?.stage)
    removeStates()
  }

  props.canvas.stage.requestRenderAll();
  props.setAnchorEl(null)
 }
  return (
    <>
      <div className="my-4 px-4" style={{height:"500px"}}>
        <div>
          <label className="px-4">
            <input
              name="apiToggle"
              type="radio"
              onClick={() => {
                setApiValue(true);
              }}
              defaultChecked
            />
            <span className="px-2"><b>Pixabay</b></span>
          </label>
          <label>
            <input
              name="apiToggle"
              type="radio"
              onClick={() => {
                setApiValue(false);
              }}
            />
            <span  className="px-2"><b>Pexels</b></span>
          </label>
          <input
            className="mx-3 my-3"
            type="text"
            placeholder="Search for Stock Images"
            onChange={(event) => {
              word = event.target.value;
              finalChangeKeyword();
            }}
          />
                       {post? 
          
  <span className="spinner-border" role="status">
    <span className="sr-only">Loading...</span>
  </span>
:null
}
        </div>
        
       
        

        <div className="px-2" id="images"  style={{height:"500px"}}>
          <div className="grid">
{(apiValue && data?
              
            data?.map((item) => {
              return (
                <div className="grid-item">
                  <img
                    className=""
                    src={apiValue ? item?.previewURL : item?.src?.small}
                    alt="pixabay"
                    onClick={() => {
                      handleData(item)
                    }}
                  />
                </div>
              );
            })
            
            :data2?.map((item) => {
              return (
                <div className="grid-item">
                  <img
                    src={item?.src.small}
                    alt="pexels"
                    onClick={() => {
                      handleData2(item)
                    }}
                  />
                </div>
              );
            }))
            
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Pixabay;
