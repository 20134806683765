import React from 'react'
import { useDispatch } from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMagnifyingGlassPlus,faMagnifyingGlassMinus} from '@fortawesome/free-solid-svg-icons'
import { addToHistory ,removeStates} from './CanvasHistory.tsx'
import { useEffect } from 'react'
import { useRef } from 'react'

const ZoomBar = (props) => {


  const zoomOutref = useRef(null)
  const dispatch=useDispatch()
   const zoomIn = () => {
        props.canvas?.stage.setZoom( props.canvas?.stage.getZoom() * 1.1);
        props.canvas?.stage.setHeight( props.canvas?.stage.getHeight() * 1.1);
        props.canvas?.stage.setWidth( props.canvas?.stage.getWidth() * 1.1);
        props.canvas?.stage.calcOffset();
        let element=document.getElementById('canvas');
        let rect=element?.getBoundingClientRect();
        dispatch({type:'UPDATE_CANVAS',height:props.canvas?.stage.getHeight(),width:props.canvas?.stage.getWidth(),rect:rect})
       dispatch({type:"INCREASE_ZOOM"})
        props.canvas?.stage.requestRenderAll();
        addToHistory(props.canvas.stage)
        removeStates()

    }

   const zoomOut = () => {
    props.canvas?.stage.setZoom(props.canvas?.stage.getZoom() / 1.1);
    props.canvas?.stage.setHeight(props.canvas?.stage.getHeight() / 1.1);
    props.canvas?.stage.setWidth(props.canvas?.stage.getWidth() / 1.1);
    props.canvas?.stage.calcOffset();
    let element=document.getElementById('canvas');
    let rect=element?.getBoundingClientRect();
    dispatch({type:'UPDATE_CANVAS',height:props.canvas?.stage.getHeight(),width:props.canvas?.stage.getWidth(),rect:rect })
    dispatch({type:"DECREASE_ZOOM"})
    props.canvas?.stage.requestRenderAll();
    addToHistory(props.canvas.stage)
    removeStates()

    }
  return (
    
    <span id='zoombar' ><button style={{fontSize:"25px"}} className="btn btn-primary-outline" title="Zoom In" onClick={zoomIn}><FontAwesomeIcon icon={faMagnifyingGlassPlus}/></button>
        <button ref={zoomOutref} title="Zoom Out" style={{fontSize:"25px"}} className="btn btn-primary-outline" onClick={zoomOut}><FontAwesomeIcon icon={faMagnifyingGlassMinus}/></button>

    </span>
  )
}

export default ZoomBar