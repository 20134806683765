import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PAGE_DIMENTIONS } from "../constants.tsx";
import {
  toggleEbook,
  changePageSize,
  changePageNumber,
  changeBinding,
} from "./actions/index.tsx";
import { getCoverSize } from "./utils.tsx";

const Bookspecification = () => {
  const ebook = useSelector((helloState: any) => {
    return helloState.ebookToggle;
  });
  let inputPageSize = useSelector((state: any) => {
    return state.pageSize;
  });
  let inputPageNumber = useSelector((state: any) => {
    return state.pageNumber;
  });
  let inputBinding = useSelector((state: any) => {
    return state.binding;
  });
  let inputbleed = useSelector((state: any) => {
    return state.bleed;
  });

  const dispatch = useDispatch();

  let pageDimension = PAGE_DIMENTIONS;

  let width: any, height: any, spineWidth: any;
  [width, height, spineWidth] = getCoverSize(
    pageDimension[inputPageSize][1],
    inputPageNumber,
    inputBinding
  );
  let spinePagewidthRatio = (2 * spineWidth) / width;



  const getPageSizeInput = () => {
    return (
      <div>
        <p style={{ marginTop: "15px" }}>Page Size:</p>
        <select
          value={inputPageSize}
          onChange={(e) => {
            dispatch(changePageSize(e.target.value));
          }}
        >
          {Object.entries(pageDimension).map(([key, value]) => {
            return <option key={key+'op'} value={key}>{value[0]}</option>;
          })}
        </select>
      </div>
    );
  };


  if (ebook) {
    dispatch({ type: "BLEED_EBOOK" });

    return (
      <div>
        <span>E-Book </span>
        <input
          id="ebook"
          type="checkbox"
          defaultValue={0}
          onChange={() => {
            dispatch(toggleEbook());
          }}
          checked={ebook}
        />
        {getPageSizeInput()}
      </div>
    );
  } else {
    return (
      
      <div>
        <div>
          <span>E-Book </span>
          <input
            id="ebook"
            type="checkbox"
            onChange={() => {
              dispatch(toggleEbook());
            }}
            defaultValue={0}
            checked={ebook}
          />

          <div>
            <p style={{ marginTop: "15px" }}>Number of Pages:</p>
            <input
              type="number"
              defaultValue={inputPageNumber}
             
              onChange={(e) => {
                 if(parseInt(e.target.value)>49 && parseInt(e.target.value)<828 )
                dispatch(changePageNumber(e.target.value));
                else if(e.target.value===""){
                  dispatch(changePageNumber("200"));
                }
              }}
            />
          </div>
          {getPageSizeInput()}

          <p style={{ marginTop: "15px" }}>Binding:</p>
          <select
            value={inputBinding}
            
            onChange={(e) => {
              dispatch(changeBinding(e.target.value));
            }}
          >
            <option value="SP">Soft Cover</option>
            <option value="HC">Hard Cover</option>
          </select>
        </div>
      </div>
    );
  }
};

export default Bookspecification;
