export const SPINE_PER_PAGE = 0.001968
export const EXTRA_SPINE = 0.157
export const FLAP = 0.787
export const EXTRA_WIDTH = 0.276
export const EXTRA_HEIGHT = 0.197

export const WIDTH_BLEED = 0.2
export const HEIGHT_BLEED = 0.2

export const WIDTH_PADDING = 0.3
export const HEIGHT_PADDING = 0.3

export const DPI = 300

export const CLIENT_DATA_VERSION = 1


export const  PROPS_TO_INCLUDE_IN_CANVAS = ['id','name','metaType']

export const PAGE_DIMENTIONS = {
    "5x7": ["5 inch x 7 inch", [5, 7]],
    "5x8": ["5 inch x 8 inch", [5, 8]],
    A5: ["A5", [5.83, 8.27]],
    "5.5x8.5": ["5.5 inch x 8.5 inch", [5.5, 8.5]],
    "6x9": ["6 inch x 9 inch", [6, 9]],
    "7x9": ["7 inch x 9 inch", [7, 9]],
    "8x11": ["8 inch x 11 inch", [8, 11]],
    A4: ["A4", [8.27, 11.69]],
    letter: ["Letter/A4", [8.5, 11]],
  };


  export const PIXABAY_BASE_URL = 'https://pixabay.com/api/?key=29377572-50108b806f6218b64bfe8673b&q='

  export const PEXELS_BASE_URL= 'https://api.pexels.com/v1/search?query='