import React,{useEffect,useState} from 'react'
import { useSelector } from 'react-redux'
import Popover from "@material-ui/core/Popover";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLayerGroup} from '@fortawesome/free-solid-svg-icons'
import '../App.css'


const ImageToolbar = (props) => {
    let dimensions=useSelector((state:any)=>{return state.dimensions})
    const [anchorEl,setAnchorEl]=useState(null);
    const open = Boolean(anchorEl);
  
    function sendToBack(){
        // let img=props.canvas?.stage.getActiveObjects().find((item:any)=>{return item.type==='image'})
                let img=props.canvas?.stage.getActiveObject()

   let frontshell=props.canvas?.stage.getObjects().find((item:any)=>{return item.name==='frontShell'})
   let backshell=props.canvas?.stage.getObjects().find((item:any)=>{return item.name==='backShell'})
   let spineshell=props.canvas?.stage.getObjects().find((item:any)=>{return item.name==='spineShell'})

   const found=  props.canvas.stage.getObjects().find((item:any)=>{return item.name==='topBorder'});


    const found1=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='leftBorder'});


    const found2=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='bottomBorder'});


    const found3=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='rightBorder'});
  
    
    const found4=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='leftSpine'});

    const found5=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='rightSpine'});
  
      

    
    const found6=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='frontpaddingtop'});


    const found7=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='frontpaddingbottom'});

    const found8=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='frontpaddingleft'});

    const found9=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='frontpaddingright'});


    const found10=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='backpaddingtop'});

    const found11=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='backpaddingbottom'});
 

    const found12=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='backpaddingleft'});
   

    const found13=props.canvas.stage.getObjects().find((item:any)=>{return item.name==='backpaddingright'});

          img.sendToBack()
          found1.sendToBack()
          found2.sendToBack()
          found3.sendToBack()
          found4.sendToBack()
          found5.sendToBack()
          found6.sendToBack()
          found7.sendToBack()
          found8.sendToBack()
          found9.sendToBack()
          found10.sendToBack()
          found11.sendToBack()
          found12.sendToBack()
          found13.sendToBack()
          frontshell.sendToBack()
          backshell.sendToBack()
          spineshell.sendToBack()
          
          img.setCoords()

        props.canvas?.stage.requestRenderAll()
    }
  return (

    <>
      

      

    
<div id="imageToolbar" title="Set Layer" className='nav-item btn btn-default'  onClick={(event)=>{ setAnchorEl(event.currentTarget);
}}><FontAwesomeIcon icon={faLayerGroup}/></div>
<Popover
  open={open}
  anchorEl={anchorEl}
  onClose={()=>{setAnchorEl(null)}}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
>

<div style={{'padding':'10px',width:"190px",height:"220px",color:'grey'}}>
   
    <div className=" my-2 px-4 py-1 my-3 hoverItem"  onClick={sendToBack}>
       Send To Back
    </div>
    <div className=" my-2 px-4 py-1 my-3 hoverItem"  onClick={()=>{
    let img=props.canvas?.stage.getActiveObject()
     img?.sendBackwards()
     img.setCoords()

    props.canvas?.stage.requestRenderAll()
        }}>Send Backward
</div>

    <div className=" my-2 px-4 py-1 my-3 hoverItem"  onClick={()=>{ 
      let img=props.canvas?.stage.getActiveObject()
      img?.bringForward()
      img.setCoords()

      props.canvas?.stage.requestRenderAll()
    }}>Bring Forward 
    </div>
    <div className=" my-2 px-4 py-1 my-3 hoverItem" onClick={()=>{
    let img=props.canvas?.stage.getActiveObject()
     img?.bringToFront()
     img.setCoords()

    props.canvas?.stage.requestRenderAll()
        }}>Bring To Front
</div>


</div>
  

</Popover>
    </>


  )
}

export default ImageToolbar