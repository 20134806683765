import React, { useEffect, useState } from 'react'
import ALIGNMENT from './Alignment.tsx'
import './styles/texttoolbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faAlignJustify,
    faBold,
    faItalic,
    faFill,
    faPen,
    faFileImage,
    faTextWidth,
    faStrikethrough,
    faArrowUpAZ,
    faBorderTopLeft,
} from '@fortawesome/free-solid-svg-icons'
import { ChromePicker, SketchPicker } from 'react-color'
import Popover from '@material-ui/core/Popover'
import { useDispatch, useSelector } from 'react-redux'
import Alignment from './Alignment'
import { addToHistory, removeStates } from './CanvasHistory.tsx'

const TextToolBar = (props: any) => {
    const [color, setColor] = useState('#fff')
    const [bgcolor, setBgColor] = useState('#fff')
    
    const [anchorBgEl, setAnchorBGEl] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorEl2, setAnchorEl2] = React.useState(null)

    const [bold, setBold] = useState(true)
    const [italic, setItalic] = useState(true)
    const [lineThrough, setLineThrough] = useState(true)
    const [upperCase, setUpperCase] = useState(true)

    const open = Boolean(anchorEl)
    const openBG = Boolean(anchorBgEl)
    
    const open2 = Boolean(anchorEl2)

    let { selectedTextToolBar } = useSelector((state: any) => {
        return state
    })

    let dispatch = useDispatch()

    let fontSizes = [
        10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40, 44, 48,
        54, 60, 66, 72,
    ]

    let fontFamilies = [
        ['Arial, sans-serif', 'Arial'],
        ['Bevan, cursive', 'Bevan'],
        ['Calibri, sans-serif', 'Calibri'],
        ['Cinzel, serif', 'Cinzel'],
        ['EB Garamond, serif', 'EB Garamond'],
        ['Exo, sans-serif', 'Exo'],
        ['Gentium Basic, serif', 'Gentium Basic'],
        ['Lato, sans-serif', 'Lato'],
        ['Open Sans, sans-serif', 'Open Sans'],
        ['Palatino, serif', 'Palatino'],
        ['Raleway, sans-serif', 'Raleway'],
        ['Roboto Slab, serif', 'Roboto Slab'],
        ['Sail, cursive', 'Sail'],
        ['Alex Brus, cursive', 'Alex Brush'],
        ['Lovers Quarrel, cursive', 'Lovers Quarrel'],
        ['Oswald, sans-serif', 'Oswald'],
        ['Montserrat Alternates, sans-serif', 'Montserrat Alternates'],
        ['Nova Round, cursive', 'Nova Round'],
        ['Supermercado One, cursive', 'Supermercado One'],
        ['Playfair Display SC, serif', 'Playfair Display SC'],
        ['Cutive, serif', 'Cutive'],
        //      ['Goudy Bookletter 1911, serif', 'Goudy Bookletter 1911'],
        ['Rye, cursive', 'Rye'],
        ['Crafty Girls, cursive', 'Crafty Girls'],
        ['Metal Mania, cursive', 'Metal Mania'],
        ['Metamorphous, cursive', 'Metamorphous'],
        ['"Orbitron", sans-serif', 'Orbitron'],
        ['VT323, monospace', 'VT323'],
        ['Rock Salt, cursive', 'Rock Salt'],
        ['Special Elite, cursive', 'Special Elite'],
        ['Arya, sans-serif', 'आर्या'],
        ['Halant, sans-serif', 'हलन्त'],
    ]

    const updateTextStyle = (canvas, key, value) => {
        let obj = canvas?.getActiveObject()
        if (obj.isEditing && obj.selectionEnd - obj.selectionStart > 1) {
            obj.setSelectionStyles({ [key]: value })
        } else {
            obj.set({ [key]: value })
        }

        canvas?.renderAll()

        return obj
    }
    
     function isTextType(type: any) {
        return type === 'textbox' 
    }
    props.canvas?.stage.on('object:modified', (object: any) => {
        if (object.target && isTextType(object.target.type)) {
            object.target.fontSize *= object.target.scaleX
            object.target.fontSize = object.target.fontSize.toFixed(0)
            object.target.scaleX = 1
            object.target.scaleY = 1
            object.target._clearCache()
        }
})

    return (
        <>
            <select
                value={Math.round(
                    (parseInt(selectedTextToolBar.fontSize) * 72) / 300
                )}
                title="Font Size"
                onChange={(event) => {
                    let value = parseInt(event.target.value)

                    let cal = Math.round((value * 300) / 72)
                    const obj = updateTextStyle(
                        props?.canvas?.stage,
                        'fontSize',
                        cal
                    )

                    // while (obj.textLines.length > 1) {
                    //   obj.set({width: obj.getScaledWidth()})
                    // }
                    // obj.set({width:"0"})
                    // obj.setCoords();
                    // props?.canvas.stage?.requestRenderAll();
                    addToHistory(props.canvas.stage)
                    removeStates()

                    dispatch({ type: 'SELECTED_OBJECT', payload: obj.toJSON() })
                }}
                style={{
                    width: '60px',
                    height: '40px',
                    textAlign: 'center',
                    borderRadius:5,
                    backgroundColor:"white"
                }}
            >
                {fontSizes.map((size, index) => {
                    return (
                        <option
                            style={{ fontSize: '16px' }}
                            key={index}
                            value={size}
                        >
                            {size}
                        </option>
                    )
                })}
            </select>

            <select
                className=''
                title="Font"
                value={selectedTextToolBar.fontFamily}
                style={{
                    marginLeft: '10px',
                    width: '150px',
                    height: '40px',
                    textAlign: 'left',
                    borderRadius:4,
                    backgroundColor:"white"
                }}
                onChange={(event) => {
                    console.log(event)
                    let fontName = event.target.value
                    const obj = updateTextStyle(
                        props?.canvas?.stage,
                        'fontFamily',
                        fontName
                    )
                    addToHistory(props.canvas.stage)
                    removeStates()

                    dispatch({ type: 'SELECTED_OBJECT', payload: obj.toJSON() })
                }}
            >
                {fontFamilies.map((family, index) => {
                    return (
                        <option
                            key={index}
                            style={{ fontFamily: family[0] }}
                            value={family[0]}
                        >
                            {family[1]}
                        </option>
                    )
                })}
            </select>

            <button
                title="Text Color"
                style={{
                    marginLeft:'10px',
                    height: '40px',
                    width: '40px',
                    border: 'solid',
                    borderWidth: '0.1px',
                    backgroundColor:"white",
                    borderTopLeftRadius:5,
                    borderBottomLeftRadius:5,     
                }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                <FontAwesomeIcon
                    color={props.canvas?.stage.getActiveObject()?.fill}
                    icon={faPen}
                />
            </button>

            <Popover
                anchorEl={anchorEl}
                open={open}
                id={open ? 'simple-popover' : undefined}
                onClose={() => {
                    setAnchorEl(null)
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
            >
                <SketchPicker
                    color={color}
                    disableAlpha={true}
                    onChange={(updatedColor) => {
                        setColor(updatedColor.hex)
                        //let obj = props.canvas?.stage.getActiveObject();
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'fill',
                            updatedColor.hex
                        )
                        //obj.setSelectionStyles({'fill':updatedColor.hex});

                        //props?.canvas.stage?.requestRenderAll();
                        addToHistory(props.canvas.stage)
                        removeStates()
                    }}
                />
            </Popover>

            <button
                title="Background Color"
                style={{
                   
                    height: '40px',
                    width: '40px',
                    border: 'solid',
                    borderWidth: '0.1px',
                    backgroundColor:"white",
                 
                }}
                onClick={(event) => {
                    setAnchorBGEl(event.currentTarget)
                }}
            >
                <FontAwesomeIcon
                    color={props.canvas?.stage.getActiveObject()?.textBackgroundColor}
                    icon={faFill}
                />
            </button>

            <Popover
                anchorEl={anchorBgEl}
                open={openBG}
                id={openBG ? 'simple-popover-bg' : undefined}
                onClose={() => {
                  setAnchorBGEl(null)
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
            >
                <SketchPicker
                    color={bgcolor}
                    disableAlpha={true}
                    onChange={(updatedColor) => {
                        setBgColor(updatedColor.hex)
                        //let obj = props.canvas?.stage.getActiveObject();
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'textBackgroundColor',
                            updatedColor.hex
                        )
                        //obj.setSelectionStyles({'fill':updatedColor.hex});

                        //props?.canvas.stage?.requestRenderAll();
                        addToHistory(props.canvas.stage)
                        removeStates()
                    }}
                />
            </Popover>

            <span>
                <ALIGNMENT canvas={props.canvas} />
            </span>
            {/* <button 
        style={{
          height: "40px",
          width: "40px",
          border: "solid",
          borderWidth: "0.1px",
        }}
        onClick={() => {
          let obj = props.canvas?.stage.getActiveObject();
          obj.set("textAlign", "justify");
          props?.canvas.stage?.requestRenderAll();
        }}
      >
        <FontAwesomeIcon icon={faAlignJustify} />
      </button> */}

            <button
                title="Bold"
                className={` ${
                    selectedTextToolBar.fontWeight === 'bold' ? 'active' : null
                }`}
                style={{
            
                    height: '40px',
                    width: '40px',
                    border: 'solid',
                    borderWidth: '0.1px',
                    backgroundColor:"white",
               

                }}
                onClick={() => {
                    setBold(!bold)
                    let obj = props.canvas?.stage.getActiveObject()
                    if (bold) {
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'fontWeight',
                            'bold'
                        )
                        props?.canvas.stage?.requestRenderAll()
                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    } else {
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'fontWeight',
                            'normal'
                        )

                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    }
                }}
            >
                <FontAwesomeIcon icon={faBold} />
            </button>
            <button
                title="Italic"
                className={` ${
                    selectedTextToolBar.fontStyle === 'italic' ? 'active' : null
                }`}
                style={{

                    height: '40px',
                    width: '40px',
                    border: 'solid',
                    borderWidth: '0.1px',
                    backgroundColor:"white",
                

                }}
                onClick={() => {
                    setItalic(!italic)
                    if (italic) {
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'fontStyle',
                            'italic'
                        )

                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    } else {
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'fontStyle',
                            'normal'
                        )

                        props?.canvas.stage?.requestRenderAll()
                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    }
                }}
            >
                <FontAwesomeIcon icon={faItalic} />
            </button>

            <button
                title="Line Through"
                className={`${
                    selectedTextToolBar.linethrough === 'true' ? 'active' : null
                }`}
                style={{

                    height: '40px',
                    width: '40px',
                    border: 'solid',
                    borderWidth: '0.1px',
                    backgroundColor:"white",
                 

                }}
                onClick={() => {
                    setLineThrough(!lineThrough)
                    if (lineThrough) {
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'linethrough',
                            true
                        )

                        props?.canvas.stage?.requestRenderAll()
                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    } else {
                        const obj = updateTextStyle(
                            props?.canvas?.stage,
                            'linethrough',
                            false
                        )

                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    }
                }}
            >
                <FontAwesomeIcon icon={faStrikethrough} />
            </button>

            <button
                title="Upper Case"
                style={{
                    height: '40px',
                    width: '40px',
                    border: 'solid',
                    borderWidth: '0.1px',
                    backgroundColor:"white",
                

                }}
                onClick={() => {
                    setUpperCase(!upperCase)
                    let obj = props.canvas?.stage.getActiveObject()
                    if (upperCase) {
                        let text = obj.text
                        obj.text = text.toUpperCase()
                        props?.canvas.stage?.requestRenderAll()
                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    } else {
                        let text = obj.text
                        obj.text = text.toLowerCase()
                        props?.canvas.stage?.requestRenderAll()
                        addToHistory(props.canvas.stage)
                        removeStates()

                        dispatch({
                            type: 'SELECTED_OBJECT',
                            payload: obj.toJSON(),
                        })
                    }
                }}
            >
                <FontAwesomeIcon icon={faArrowUpAZ} />
            </button>

            <button
                title="Character Spacing"
                style={{
                    height: '40px',
                    width: '40px',
                    border: 'solid',
                    borderWidth: '0.1px',
                    backgroundColor:"white",
                    borderTopRightRadius:5,
                    borderBottomRightRadius:5,
                 

                }}
                id="opacitySlider"
                className=""
                onClick={(event) => {
                    setAnchorEl2(event.currentTarget)
                }}
            >
                {' '}
                <FontAwesomeIcon icon={faTextWidth} />
            </button>

            <Popover
                anchorEl={anchorEl2}
                open={open2}
                id={open2 ? 'simple-popover' : undefined}
                onClose={() => {
                    setAnchorEl2(null)
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
            >
                <div className="mx-3 my-3">
                    <label className="px-2" htmlFor="charspacing">
                        Char Spacing
                    </label>

                    <input
                        onChange={(event) => {
                            let char = parseInt(event.target.value) * 10
                            let obj = props.canvas?.stage.getActiveObject()
                            obj.set({ charSpacing: char })

                            props.canvas.stage.requestRenderAll()
                            addToHistory(props.canvas.stage)
                            removeStates()

                            dispatch({
                                type: 'SELECTED_OBJECT',
                                payload: obj.toJSON(),
                            })
                        }}
                        className="charSpacing"
                        value={Math.round(
                            selectedTextToolBar.charSpacing / 10
                        ).toString()}
                        type="range"
                        max="100"
                    />
                </div>
            </Popover>
        </>
    )
}
export default TextToolBar
