import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Canvas from "./components/Canvas.tsx";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import TopMenuBar from "./components/TopMenuBar.tsx";
import WebFont from "webfontloader";
import "./App.css";
import ZoomBar from "./components/ZoomBar.tsx";
import Grid from "./components/Grid.tsx";
import Scale from "./components/Scale.tsx";
import Overlay from "./components/Overlay.tsx";
import './components/styles/style.scss'




function App(props) {
  
  const [wrapper,setWrapper]=useState()


  const [canvas, setCanvas] = useState(null);
  const [toggle,setBdToggle]=useState(false)
  const [textToggle,setTextToggle]=useState(false)
  const [textInput,setTextInput]=useState()
  const [textObject,setTextObject]=useState()
  const [modalHeader,setModalHeader]=useState()
  let { selectedTextToolBar } = useSelector((state) => {
    return state
  })

  useEffect(()=>{

let wraps=document.getElementById('wrapper-canvas').getBoundingClientRect()
setWrapper(wraps)

let obj=canvas?.stage.getActiveObject();
if(obj?.angle===90){
  
  setTextObject(obj)
setTextInput(obj.text)

    if(obj.name==="spineTitle"){
    setModalHeader("Edit Spine Title Text")
    }
    else if (obj.name==="spineAuthor"){
    setModalHeader("Edit Spine Author Text")
    }
    obj.on('editing:entered', () => {
      setTextToggle(true);
    })
    obj.on('editing:exited', () => {
      setTextToggle(false);
    })

  
}
  
  },[selectedTextToolBar])

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Alex Brush",
          "Arya",
          "Bevan",
          "Cinzel",
          "Crafty Girls",
          "Cutive",
          "EB Garamond",
          "Exo:400,700",
          "Gentium Basic",
          "Goudy Bookletter 1911",
          "Lato",
          "Lovers Quarrel",
          "Metal Mania",
          "Metamorphous",
          "Montserrat Alternates:400,700",
          "Nova Round",
          "Open Sans",
          "Orbitron",
          "Oswald:400,700",
          "Playfair Display SC:900",
          "Raleway",
          "Roboto Slab",
          "Rock Salt",
          "Rye",
          "Sail",
          "Special Elite",
          "Supermercado One",
          "Halant",
          "VT323",
        ],
      },
    });
  }, []);


  

  document.addEventListener("click", (e) => {
    if (e.target.children[0])
      if (
        e.target.nodeName !== "CANVAS" &&
        e.target.children[0]?.className === "canvas-container"
      ) {
        canvas?.stage.discardActiveObject();
        canvas?.stage.requestRenderAll();
      }

    if (e.target.className === "wrapper") {
      canvas?.stage.discardActiveObject();
      canvas?.stage.requestRenderAll();
    }
  });

  return (
    <div className="App" style={{zIndex:"auto"}}>
      <div className="sticky-top bg-light"  style={{height:"13vh",zIndex:"999",borderBottom:'1px solid #ccc',backgroundColor:"white"}}>
      <TopMenuBar canvas={canvas} />
      </div>
 
  
      <div style={{height:'83vh', overflowY: 'scroll'}}>
      {/* <Scale canvas={canvas}/> */}

      <Canvas 
       
        onSuccess={async (canvas) => {
          setCanvas(canvas);
        }}
      />
      </div>
     <div style={{zIndex:1000}}>
      {canvas? <Overlay canvas={canvas} setBdToggle={setBdToggle} />:null}
      </div>
      <div className="fixed-bottom col-lg-7 col-sm-7 offset-6 " style={{height:"8vh"}}>
    
         
            {canvas?<ZoomBar canvas={canvas} />:null}
            {canvas?<Grid canvas={canvas} />:null}
            
   

      </div>
    {toggle?<div className="modal-backdrop fade show" style={{zIndex:1000}}></div>:null}
    <div>
    <div class={`modal fade ${textToggle?"show":null}`} style={{display:textToggle?'block':''}} id="textModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{modalHeader }</h5>
        <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Done"
              onClick={()=>{
                setTextToggle(false)
              }}
             
            ></button>         
      </div>
      <div class="modal-body">
        <input className="form-group" style={{width:"100%", textAlign:"center"}} type="textArea" value={textInput} onChange={(event)=>{setTextInput(event.target.value)
        }}/>
      </div>
      <div class="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{setTextToggle(false)}}>Close</button>
        <button type="button" className="btn btn-primary" onClick={()=>{
         textObject?.set({"text":textInput})
         setTextToggle(false);
        canvas?.stage.renderAll()
        }}>Apply</button>
      </div>
    </div>
  </div>
</div>
    </div>
    </div>
  );
}

export default App;
