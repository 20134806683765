import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { addToHistory,removeStates } from './CanvasHistory.tsx';


const Uploads = (props) => {
  
 
   let dispatch=useDispatch();
   let file=useSelector((state:any)=>{return state.canvasimage})
   const value = useSelector((state: any) => {
    return state.value
})
   const dimensions = useSelector((state: any) => {
    return state.dimensions
  })
  
  const paddingDimensions = useSelector((state: any) => {
    return state.paddingDimensions
  })
  
 function readFetcher(){
    var reader = new window.FileReader()
    
    reader.onload = (event) => {
      var image:any = new window.Image()
      image.src = event.target.result
      image.onload = (i:any) => {

        let data = {
          'src': image.src,
          'left': 0,
          'top': 0,
        }

        let img = data
        // props.canvas?.addImage(img.src, {
        //     name: 'image',
        //     left:0,
        //     top:0,
          
        // })

        if(value==="front"){
       
          props.canvas?.addImage(img.src, {
            name: "image",
            left: paddingDimensions.frontpaddingleft.left,
            top: dimensions.height*1/3,
          });
          props.canvas?.stage.requestRenderAll();
          props.setAnchorEl(null)
          addToHistory(props.canvas?.stage)
          removeStates()
        }
        else if (value==="back"){
             
          props.canvas?.addImage(img.src, {
            name: "image",
            left: paddingDimensions.backpaddingleft.left,
            top: dimensions.height*1/3,
          });
          props.canvas?.stage.requestRenderAll();
          props.setAnchorEl(null)
          addToHistory(props.canvas?.stage)
          removeStates()
        }
        else if (value==="spine"){
           
          props.canvas?.addImage(img.src, {
            name: "image",
            left: (dimensions.width/2)-(img.src.width/2),
            top: 100,
          });
          props.canvas?.stage.requestRenderAll();
          props.setAnchorEl(null)
          addToHistory(props.canvas?.stage)
          removeStates()
        }
      
        else if (value===null)
        {
           
          props.canvas?.addImage(img?.src, {
            name: "image",
            left: paddingDimensions.backpaddingleft.left+dimensions.spinewidth,
            top: dimensions.height*1/3,
          });
          props.canvas.stage.requestRenderAll();
          props.setAnchorEl(null)
          addToHistory(props.canvas?.stage)
          removeStates()
        }
      
        props.canvas.stage.requestRenderAll();
        props.setAnchorEl(null)
           
        const found=  props.canvas.stage.getObjects().find((item:any)=>{return item.name==='image'});
        found.sendToBack()


        props.canvas?.stage.requestRenderAll();
        props.setAnchorEl(null)

      }
    }
    reader.readAsDataURL(file)
 }
  return (
    <>
      
      <div style={{marginTop:"50px",marginBottom:"50px",marginRight:"20px",marginLeft:"20px",border:"2px dashed #D3D3D3",padding:"20px",height:"150px",width:"350px"}}>
        
         
          <input style={{padding:"10px"}} type='file' onChange={(event)=>{
              dispatch({type:'IMAGE_UPLOAD',file:event.target.files[0]})
              }}/>
           
       <button className='btn btn-primary' style={{marginLeft:"250px",marginTop:"20px"}} onClick={()=>{
                      readFetcher();

           }}>Done</button>
      </div>
      
        
    </>
  )
}

export default Uploads