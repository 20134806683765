import { jsPDF } from "jspdf";
import React, { useContext, useEffect, useState } from "react";
import {fabric} from 'fabric';
import { getCoverSize } from './utils.tsx'
import { useSelector,useDispatch } from "react-redux";
import { PAGE_DIMENTIONS } from "../constants.tsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'

const Download = (props): React.ReactElement => {
  const [isShowSvg, setIsShowSvg] = useState(true);
  let tempCanvas = new fabric.Canvas('canvas-element');
  let inputPageSize=useSelector((state:any)=>{return state.pageSize});
  let inputPageNumber=useSelector((state:any)=>{return state.pageNumber})
  let inputBinding=useSelector((state:any)=>{return state.binding})

  let dispatch=useDispatch();
  const exportImageData = (
    canvas: fabric.Canvas | null,
    width: any,
    height: any,
    zoom: any,
    format?: string
  ) => {
    if (canvas) {
      // hide borders and deselect selected element
      canvas?.requestRenderAll();
      canvas?.discardActiveObject();
      // resize canvas to actual size and zoom
      canvas?.setDimensions({ width: width, height: height });
      canvas?.setZoom(zoom);
      // capture canvas as image
      const imgData = canvas?.toDataURL({ format: format || "png" });
      return imgData;
    } else {
      return "";
    }
  };

  const downloadSVG = async (canvas) => {
    if (
      canvas?._objects.length !== 0 ||
      canvas?.backgroundColor !== "#FFFFFF"
    ) {

      const filedata = canvas?.toSVG(); // the SVG file is now in filedata
      if (filedata) {
        const locfile = new Blob([filedata], {
          type: "image/svg+xml;charset=utf-8",
        });
        const locfilesrc = URL.createObjectURL(locfile); //mylocfile);
        const link = document.createElement("a");
        if (locfilesrc) {
          link.href = locfilesrc;
          link.download = `Image.svg`;
          document.body.appendChild(link);
          link.click();
        }
      }
    }
  };

  function hideBorders(cloneCanvas:any) {
    if(tempCanvas){
    let found = []
    found = cloneCanvas?.getObjects().filter((item: any) => {
        if (item.type ==='line') {
            return found.push(item)
        }
    })
    found?.map((item) => {
        cloneCanvas?.remove(item)
    })
    cloneCanvas?.renderAll()
  }
}


  function downloadPDF (canvas) {
    let [width, height, spineWidth] = getCoverSize(PAGE_DIMENTIONS[inputPageSize][1],inputPageNumber,inputBinding)
    // if (ebook) {
    //         width = (width - spineWidth) / 2
    //         spineWidth = 0
    //       }
          let dpi = 300;
         let [printWidth, printHeight] = [width / dpi,height / dpi]
    
        //  setValue(null);
        //  dispatch({type:'SET_VALUE',payload:null})

        // props.canvas?.stage.requestRenderAll()
         let imgData = exportImageData(canvas, width, height, 1,)
     
         // landscape or portrait?
         let mode = printWidth > printHeight ? 'l' : 'p'
         var pdf = new jsPDF(mode, 'in', [printWidth, printHeight])

         pdf.addImage(imgData, 'JPEG', 0, 0,printWidth,printHeight)
         let name = "bookcover"
      
         pdf.save( name + '.pdf')
     

}



  // from Abhinav's answer at  https://stackoverflow.com/questions/37135417/download-canvas-as-png-in-fabric-js-giving-network-error/
  const dataURLtoBlob = (dataurl: string) => {
    const parts = dataurl.split(",");
    if (parts != null) {
      const allParts = parts[0].match(/:(.*?);/);
      const mime = (allParts && allParts[1]) || "";
      if (parts[0].indexOf("base64") !== -1) {
        const bstr = atob(parts[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
      } else {
        const raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: mime });
      }
    } else {
      return null;
    }
  };

  const downloadPNG = (canvas,format: string) => {
    // const canvas = await getStrippedCanvas(items.canvas)
    if (
      canvas?._objects.length
     
    ) {
     
      const width: any = canvas?.width;
      const height: any = canvas?.height;
      const imgData: any = exportImageData(canvas, width, height, 1, format);
      const blob = dataURLtoBlob(imgData);
      const a = document.createElement("a");
      const link = document.createElement("a");
      if (blob) {
        link.href = URL.createObjectURL(blob);
        link.download = `Image.${format}`;
        document.body.appendChild(link);
        link.click();
      }
    }
  };
  const downloadJPEG = () => {
    console.log("in jpeg");
  };

//   const 11downloadPDF = (canvas) => {
//     const pdf = new jsPDF({
//       orientation: "p",
//       unit: "in",
//       format: "a4",
//       putOnlyUsedFonts: true,
//     });
//     let mode: any | undefined;
//     let printHeight: any | undefined;
//     let printWidth: any | undefined;
//     let imgData: any | undefined;

//     if (
//      canvas?._objects.length !== 0 ||
//       canvas?.backgroundColor !== "#FFFFFF"
//     ) {
 
//       const width: any = canvas?.width;
//       const height: any = canvas?.height;
//       const dpi = 96;
//       [printWidth, printHeight] = [width / dpi, height / dpi];
//       imgData = exportImageData(canvas, width, height, 1);
//       // landscape or portrait?
//       mode = printWidth > printHeight ? "l" : "p";
//       pdf.addPage();
//       pdf.addImage(imgData, "JPEG", 0.2, 0.2, printWidth, printHeight);
//     }

//     pdf.deletePage(1);
//     if (imgData !== undefined) {
//       pdf.save("bannerbuz" + ".pdf");
//     }
//   };
  const processDownload = (type: string) => {
   
    setTimeout(()=>{
 if (type == "pdf") {
    downloadPDF(tempCanvas);
  } else if (type == "png") {
    downloadPNG(tempCanvas,"png");
  } else if (type == "jpeg") {
    downloadPNG(tempCanvas,"jpeg");
  } else if (type == "svg") {
    downloadSVG(tempCanvas);
  }
    },500)
   
  };
  return (
    <>
      {/* <ul className="nav-right-side list-inline d-flex align-items-center mb-0"> */}
     
        <span className="dropdown">
          <span
            className=" dropdown-toggle  mx-4 btn btn-outline-dark "
            
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faDownload}/>
          </span>
          <ul className="dropdown-menu">
            <li style={{ width: "100%" }}>
              <span
                className="dropdown-item"
                onClick={() => {
                  let clone=JSON.stringify(props.canvas)

                  tempCanvas?.loadFromJSON(clone,function(){tempCanvas.requestRenderAll()})
                  dispatch({type:"SET_VALUE",payload:null})
                  setTimeout(()=>{
                    hideBorders(tempCanvas)
                    tempCanvas.renderAll()
                  },500)
                 
                  setTimeout(()=>{
                    processDownload("pdf");
  
                  },1200)
                }}
              >
                PDF
              </span>
            </li>

            {/* <li style={{ width: "100%" }}>
              <a
                className="dropdown-item"
                onClick={() => {
                  processDownload("png");
                }}
              >
                PNG
              </a>
            </li>

            <li style={{ width: "100%" }}>
              <a
                className="dropdown-item"
                onClick={() => {
                  processDownload("jpeg");
                }}
              >
                JPEG
              </a>
            </li>

            <span>
              <li style={{ width: "100%" }}>
                <hr className="dropdown-divider" />
              </li>

              <li style={{ width: "100%" }}>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    processDownload("svg");
                  }}
                >
                  SVG
                </a>
              </li>
            </span> */}
          </ul>
        </span>
 
    </>
  );
};
export const DownloadPDF = Download;
