import { eventNames } from "process";
import React, { useEffect, useState, useRef } from "react";
import { fabric } from 'fabric'
import FBCanvas from "./CanvasManager.tsx";
import useFabric from "./customHooks/useFabric.tsx";
import { useDispatch, useSelector } from "react-redux";
import * as constants from '../constants.tsx'
import { getCoverSize } from './utils.tsx'
import Scale from './Scale.tsx';




import { addToHistory, resetHistory, removeStates } from './CanvasHistory.tsx'

import "./styles/canvas.css";
import { pageNumber, pageSize } from "./reducers/textReducer";

const Canvas = (props) => {
  const [can, setCan] = useState(null);
  const [selectedCan, setSelectedCan] = useState(null);

  
  const canvas = new FBCanvas(can);
  

  const dispatch = useDispatch();

  const ebook = useSelector((helloState: any) => {
    return helloState.ebookToggle
})
let textEvent = useSelector((state: any) => {
    return state.textToolBar
})
let inputPageSize = useSelector((state: any) => {
    return state.pageSize
})
let inputPageNumber = useSelector((state: any) => {
    return state.pageNumber
})
let inputBinding = useSelector((state: any) => {
    return state.binding
})
let bleed = useSelector((state: any) => {
    return state.bleed
})
let canvasSize = useSelector((state: any) => {
    return state.canvasSize
})
const dimensions = useSelector((state: any) => {
    return state.dimensions
})
const prevdimensions = useSelector((state: any) => {
    return state.prevDimensions
})
const paddingDimensions = useSelector((state: any) => {
    return state.paddingDimensions
})
const finalDimension = useSelector((state: any) => {
  return state.finalDimension
})
// let canvas = new fabric.Canvas('canvas-element')

let { selectedTextToolBar } = useSelector((state: any) => {
    return state
})
const doneButton = useSelector((state: any) => {
    return state.doneButton;
  });

const [selectedCanvas, setSelectedCanvas] = useState(null)




let pageDimension = constants.PAGE_DIMENTIONS

  const canvasRef = useFabric(
    (fabricCanvas) => {
      return setCan(fabricCanvas);
    },
    {
      height: 0,
      width: 0,
      backgroundColor: "white",
      preserveObjectStacking: true,
    }
  );

 let tempHeight=null
let tempWidth=null
let tempRightspine=null
let tempLeftspine=null
let tempSpinewidth=null
  function setupDimensions() {
    if(canvas?.stage){
    // div width in pixels
    let wraps=document.getElementById('wrapper-canvas').getBoundingClientRect()

    // let maxwidth = ebook ? (wraps.width-wraps.width*0.5)/2 : wraps.width-wraps.width*0.5
    // sets up separate width, height values for showing and printing the cover
    // this.width and this.height are the actual width and height of the cover in pixels
    // this.canvasWidth and canvasHeight are the dimensions in which the canvas is showed
    // to the user

    let [width, height, spineWidth] = getCoverSize(
        pageDimension[inputPageSize][1],
        inputPageNumber,
        inputBinding
    )
    let [bleedWidth, bleedHeight] = bleed

    if (ebook) {
        width = (width - spineWidth) / 2
        spineWidth = 0
    }

    
    // let dpi = 300;
    // let [printWidth, printHeight] = [width / dpi,height / dpi]

     const scale =  Math.min((wraps.width-wraps.width*0.1)/width, (wraps.height-wraps.height*0.1)/height)
     dispatch({type:"SET_SCALE",payload:scale})

    // let zoom = maxwidth / width

   canvas?.stage?.setZoom(scale)
    let canvasWidth = width* scale
    let canvasHeight = height * scale
    canvas?.stage.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
    })

    const found = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'topBorder'
    })
    canvas?.stage.remove(found)
    let topborder = new fabric.Line([0, bleedHeight, width, bleedHeight], {
        stroke: '#000',
        strokeWidth: 3,
        selectable: false,
        name: 'topBorder',
    })
    canvas?.stage.add(topborder)

    const found1 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'leftBorder'
    })
    canvas?.stage.remove(found1)
    let leftborder = new fabric.Line([bleedWidth, 0, bleedWidth, height], {
        stroke: '#000',
        strokeWidth: 3,
        selectable: false,
        name: 'leftBorder',
    })
    canvas?.stage.add(leftborder)

    const found2 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'bottomBorder'
    })
    canvas?.stage.remove(found2)
    let bottomborder = new fabric.Line(
        [0, height - bleedHeight, width, height - bleedHeight],
        {
            stroke: '#000',
            strokeWidth: 3,
            selectable: false,
            name: 'bottomBorder',
        }
    )
    canvas?.stage.add(bottomborder)

    const found3 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'rightBorder'
    })
    canvas?.stage.remove(found3)
    let rightborder = new fabric.Line(
        [width - bleedWidth, 0, width - bleedWidth, height],
        {
            stroke: '#000',
            strokeWidth: 3,
            selectable: false,
            name: 'rightBorder',
        }
    )
    canvas?.stage.add(rightborder)
    canvas?.stage.requestRenderAll()

    // draw spines

    let leftSpine = width / 2 - spineWidth / 2
    const found4 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'leftSpine'
    })
    canvas?.stage.remove(found4)
    let leftspine = new fabric.Line([leftSpine, 0, leftSpine, height], {
        stroke: '#000',
        strokeWidth: 3,
        selectable: false,
        name: 'leftSpine',
    })

    const found5 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'rightSpine'
    })
    canvas?.stage.remove(found5)
    let rightspine = new fabric.Line(
        [leftSpine + spineWidth, 0, leftSpine + spineWidth, height],
        {
            stroke: '#000',
            strokeWidth: 3,
            selectable: false,
            name: 'rightSpine',
        }
    )
    dispatch({
        type: 'SET_DIMENSIONS',
        leftspine: leftSpine,
        spinewidth: spineWidth,
        height: height,
        width: width,
        rightspine: leftSpine + spineWidth,
    })

   setTimeout(()=>{
    tempLeftspine=leftSpine
    tempWidth=width
    tempHeight=height
    let v=leftSpine+spineWidth
    tempRightspine=v
    tempSpinewidth=spineWidth
   },(100))

    
    if (!ebook) {
        canvas?.stage.add(leftspine)
        canvas?.stage.add(rightspine)
    } else {
        leftspine = leftborder
        rightspine = leftborder
        leftspine.set('fill', 'transparent')
        rightspine.set('fill', 'transparent')
        canvas?.stage.requestRenderAll()
    }

    let [paddingwidth, paddingheight] = [
        constants.WIDTH_PADDING * constants.DPI,
        constants.HEIGHT_PADDING * constants.DPI,
    ]
    // draw front cover safe area lines

    const found6 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'frontpaddingtop'
    })
    canvas?.stage.remove(found6)

    let frontpaddingtop = new fabric.Line(
        [
            rightspine?.left + paddingwidth,
            topborder?.top + paddingheight,
            rightborder?.left - paddingwidth,
            topborder?.top + paddingheight,
        ],
        {
            stroke: '#00e600',
            strokeWidth: 6,
            strokeDashArray: [30],
            selectable: false,
            name: 'frontpaddingtop',
        }
    )
    canvas?.stage.add(frontpaddingtop)

    const found7 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'frontpaddingbottom'
    })
    canvas?.stage.remove(found7)

    let frontpaddingbottom = new fabric.Line(
        [
            rightspine.left + paddingwidth,
            bottomborder.top - paddingheight,
            rightborder.left - paddingwidth,
            bottomborder.top - paddingheight,
        ],
        {
            stroke: '#00e600',
            strokeWidth: 6,
            strokeDashArray: [30],
            selectable: false,
            name: 'frontpaddingbottom',
        }
    )
    canvas?.stage.add(frontpaddingbottom)

    const found8 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'frontpaddingleft'
    })
    canvas?.stage.remove(found8)

    let frontpaddingleft = new fabric.Line(
        [
            rightspine.left + paddingwidth,
            topborder.top + paddingheight,
            rightspine.left + paddingwidth,
            bottomborder.top - paddingheight,
        ],
        {
            stroke: '#00e600',
            strokeWidth: 6,
            strokeDashArray: [30],
            selectable: false,
            name: 'frontpaddingleft',
        }
    )
    canvas?.stage.add(frontpaddingleft)

    const found9 = canvas?.stage.getObjects().find((item: any) => {
        return item.name === 'frontpaddingright'
    })
    canvas?.stage.remove(found9)

    let frontpaddingright = new fabric.Line(
        [
            rightborder.left - paddingwidth,
            topborder.top + paddingheight,
            rightborder.left - paddingwidth,
            bottomborder.top - paddingheight,
        ],
        {
            stroke: '#00e600',
            strokeWidth: 6,
            strokeDashArray: [30],
            selectable: false,
            name: 'frontpaddingright',
        }
    )
    canvas?.stage.add(frontpaddingright)

    if (ebook) {
      dispatch({ type: 'SET_VALUE', payload: null })
      paddingwidth = 0
        paddingheight = 0

        // draw backcover safe area lines
        const found10 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingtop'
            })
        canvas?.stage.remove(found10)
        let backpaddingtop = new fabric.Line(
            [
                leftborder.left + paddingwidth,
                topborder.top + paddingheight,
                leftspine.left - paddingwidth,
                topborder.top + paddingheight,
            ],
            {
                stroke: 'transparent',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingtop',
            }
        )
        canvas?.stage.add(backpaddingtop)

        const found11 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingbottom'
            })
        canvas?.stage.remove(found11)
        let backpaddingbottom = new fabric.Line(
            [
                leftborder.left + paddingwidth,
                bottomborder.top - paddingheight,
                leftspine.left - paddingwidth,
                bottomborder.top - paddingheight,
            ],
            {
                stroke: 'transparent',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingbottom',
            }
        )
        canvas?.stage.add(backpaddingbottom)

        const found12 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingleft'
            })
        canvas?.stage.remove(found12)
        let backpaddingleft = new fabric.Line(
            [
                leftborder.left + paddingwidth,
                topborder.top + paddingheight,
                leftborder.left + paddingwidth,
                bottomborder.top - paddingheight,
            ],
            {
                stroke: 'transparent',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingleft',
            }
        )
        canvas?.stage.add(backpaddingleft)

        const found13 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingright'
            })
        canvas?.stage.remove(found13)
        let backpaddingright = new fabric.Line(
            [
                leftspine.left - paddingwidth,
                topborder.top + paddingheight,
                leftspine.left - paddingwidth,
                bottomborder.top - paddingheight,
            ],
            {
                stroke: 'transparent',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingright',
            }
        )
        canvas?.stage.add(backpaddingright)
        dispatch({
            type: 'SET_PADDINGDIMENSIONS',
            frontpaddingleft: frontpaddingleft,
            frontpaddingright: frontpaddingright,
            backpaddingleft: backpaddingleft,
            backpaddingright: backpaddingright,
        })
    } else {
        // draw backcover safe area lines
        const found10 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingtop'
            })
        canvas?.stage.remove(found10)
        let backpaddingtop = new fabric.Line(
            [
                leftborder.left + paddingwidth,
                topborder.top + paddingheight,
                leftspine.left - paddingwidth,
                topborder.top + paddingheight,
            ],
            {
                stroke: '#00e600',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingtop',
            }
        )
        canvas?.stage.add(backpaddingtop)

        const found11 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingbottom'
            })
        canvas?.stage.remove(found11)
        let backpaddingbottom = new fabric.Line(
            [
                leftborder.left + paddingwidth,
                bottomborder.top - paddingheight,
                leftspine.left - paddingwidth,
                bottomborder.top - paddingheight,
            ],
            {
                stroke: '#00e600',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingbottom',
            }
        )
        canvas?.stage.add(backpaddingbottom)

        const found12 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingleft'
            })
        canvas?.stage.remove(found12)
        let backpaddingleft = new fabric.Line(
            [
                leftborder.left + paddingwidth,
                topborder.top + paddingheight,
                leftborder.left + paddingwidth,
                bottomborder.top - paddingheight,
            ],
            {
                stroke: '#00e600',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingleft',
            }
        )
        canvas?.stage.add(backpaddingleft)

        const found13 = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'backpaddingright'
            })
        canvas?.stage.remove(found13)
        let backpaddingright = new fabric.Line(
            [
                leftspine.left - paddingwidth,
                topborder.top + paddingheight,
                leftspine.left - paddingwidth,
                bottomborder.top - paddingheight,
            ],
            {
                stroke: '#00e600',
                strokeWidth: 6,
                strokeDashArray: [30],
                selectable: false,
                name: 'backpaddingright',
            }
        )
        canvas?.stage.add(backpaddingright)
        dispatch({
            type: 'SET_PADDINGDIMENSIONS',
            frontpaddingleft: frontpaddingleft,
            frontpaddingright: frontpaddingright,
            backpaddingleft: backpaddingleft,
            backpaddingright: backpaddingright,
        })
    }
  
    canvas?.stage.requestRenderAll()

    let rightSpine = parseInt(leftSpine + spineWidth)
    const shellProps = {
        stroke: '',
        strokeWidth: 5,
        lockScalingX: true,
        lockScalingY: true,
        lockSkewingX: true,
        lockSkewingY: true,
        originX: 'center',
        originY: 'center',
        selectable: false,
        metaShell: 'shell',
        fill: '',
    }

    const foundShell1 = canvas?.stage
        .getObjects()
        .find((item: any) => {
            return item.name === 'backShell'
        })
    const foundShell2 = canvas?.stage
        .getObjects()
        .find((item: any) => {
            return item.name === 'frontShell'
        })
    const foundShell3 = canvas?.stage
        .getObjects()
        .find((item: any) => {
            return item.name === 'spineShell'
        })
    // let backShellColor=foundShell1.fill
    // let spineShellColor=foundShell3.fill
    let frontcolor = ''
    let backcolor = ''
    let spinecolor = ''
    if (foundShell2) {
        frontcolor = foundShell2.fill
    }
    if (foundShell1) {
        backcolor = foundShell1.fill
    }

    if (foundShell3) {
        spinecolor = foundShell3.fill
    }
    canvas?.stage.remove(foundShell1)

    canvas?.stage.remove(foundShell2)

    canvas?.stage.remove(foundShell3)

    var backPath = `M 0 0 L ${leftSpine} 0 L ${leftSpine} ${height} L 0 ${height} z`
    var shell = new fabric.Path(backPath, {
        ...shellProps,
        name: 'backShell',
        fill: backcolor,
        perPixelTargetFind: true,
    })

    canvas?.add(shell)
    shell.sendToBack()

    var frontPath = `M ${rightSpine} 0 L ${width} 0 L ${width} ${height} L ${rightSpine} ${height} z`

    var shell2 = new fabric.Path(frontPath, {
        fill: frontcolor,
        stroke: '',
        strokeWidth: 5,
        lockScalingX: true,
        lockScalingY: true,
        lockSkewingX: true,
        lockSkewingY: true,
        originX: 'center',
        originY: 'center',
        name: 'frontShell',
        selectable: false,
        // metaShell: 'shell',
        perPixelTargetFind: true,
    })

    canvas?.add(shell2)
    shell2.sendToBack()

    var spinePath = `M ${leftSpine} 0 L ${rightSpine} 0 L ${rightSpine} ${height} L ${leftSpine} ${height} z`

    var shell3 = new fabric.Path(spinePath, {
        ...shellProps,
        name: 'spineShell',
        fill: spinecolor,
        perPixelTargetFind: true,
    })

    canvas?.add(shell3)
    shell3.sendToBack()

    canvas?.stage.requestRenderAll()

    dispatch({ type: 'CANVAS_TOGGLE' })

    if (canvas?.stage && paddingDimensions && dimensions) {
        const bookTitle = canvas?.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'book_title'
            })

        let topoffsetratio1 = 0.2
        bookTitle?.set({
            top: height * topoffsetratio1,
            left: frontpaddingleft.left,
            width:
                frontpaddingright.left && frontpaddingleft.left
                    ? frontpaddingright.left - frontpaddingleft.left
                    : 0,
            area: 'front',
        })
  
        bookTitle?.bringToFront()
        bookTitle?.setCoords()

        const subTitle = canvas.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'subtitle'
            })
        let topoffsetratio2 = 0.5
        subTitle?.set({
            top: height * topoffsetratio2,
            left: frontpaddingleft.left,
            width:
                frontpaddingright.left && frontpaddingleft.left
                    ? frontpaddingright.left - frontpaddingleft.left
                    : 0,
            area: 'front',
        })
       

        subTitle?.bringToFront()
        subTitle?.setCoords()

        const author = canvas.stage.getObjects().find((item: any) => {
            return item.name === 'author'
        })
        let topoffsetratio3 = 0.8
        author?.set({
            top: height * topoffsetratio3,
            left: frontpaddingleft.left,
            width:
                frontpaddingright.left && frontpaddingleft.left
                    ? frontpaddingright.left - frontpaddingleft.left
                    : 0,
            area: 'front',
        })

    
        author?.bringToFront()
        author?.setCoords()

        const spineTitle = canvas.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'spineTitle'
            })
        const spineAuthor = canvas.stage
            .getObjects()
            .find((item: any) => {
                return item.name === 'spineAuthor'
            })

        if (!ebook) {
            let topoffsetratio4 = 0.8
            spineTitle?.set({
                top: height * topoffsetratio1 + 300,
                left: width / 2 + spineTitle.height / 2,
                angle: 90,
                fill: 'black',
                text: bookTitle.text,
            })

            spineTitle?.bringToFront()
            spineTitle?.setCoords()

            //  let topoffsetratio5 = 0.8
            spineAuthor?.set({
                top: height * topoffsetratio3,
                left: width / 2 + spineAuthor.height / 2,
                fill: 'black',
                text: author.text,
            })

        
            spineAuthor?.bringToFront()
            spineAuthor?.setCoords()
            canvas?.stage.renderAll()
        } else if (ebook) {
            spineTitle?.set({
                top: -100,
                left: -100,
                fill: 'transparent',
                text: author.text,
            })

            spineAuthor?.set({
                top: -100,
                left: -100,
                fill: 'transparent',
                text: author.text,
            })
            spineAuthor?.setCoords()
            canvas?.stage.renderAll()
        }
    }

    canvas?.stage.renderAll()
    let element = document.getElementById('canvas')
    let rect = element.getBoundingClientRect()
    dispatch({
        type: 'UPDATE_CANVAS',
        height: canvasHeight,
        width: canvasWidth,
        rect: rect,
    })
    let heightRatio = height / prevdimensions.height
    let widthRatio = width / prevdimensions.width
    let allobjects = selectedCanvas?.getObjects()

    canvas?.stage.calcOffset()

    canvas?.stage.requestRenderAll()
    resetHistory()
    addToHistory(canvas?.stage)
    removeStates()

  }

 
}

useEffect(()=>{
  setTimeout(()=>{  
    if(canvas?.stage){
    setupDimensions()
    dispatch({type:"SET_DONE"})
    }
  },(100))


  setTimeout(()=>{
    if(doneButton && canvas?.stage){
    
        let array=[];
        canvas?.stage.getObjects().filter((item:any)=>{
    
          if(item.type==="image"){
            array.push(item);
          }
      })
      array?.map((item)=>{

      if(item?.name==='FULL_COVER'){
        handleBackground1(item)
      }
      else if(item?.name==='FRONT_COVER'){

        handleFront1(item)
      }
      
      else if(item?.name==='BACK_COVER'){
        handleBack1(item)
      }
      else if(item?.name==='SPINE_COVER'){
        handleSpine1(item)
      }
      })
     dispatch({type:"SET_DONE"})
      }
},(300))

  
    },[finalDimension,canvas.stage,ebook,inputBinding,inputPageNumber,inputPageSize])
  
 
    
    function handleFront1(img) {
        if(canvas?.stage){
        img?.bringToFront()
     
        img?.setCoords()
    
    
        img?.set({
         
            'top': 0,
            'left':(tempWidth+tempSpinewidth)/2,
            // scaleX: (((dimensions.width + dimensions.spinewidth) / 2) *imgAspectRatio/ img.width),
            // scaleY:dimensions.height*imgAspectRatio/img.height,
            'hasControls':true,
            'selectable':true,
            'scaleX':(((tempWidth - tempSpinewidth)/ 2)/img?.width) ,
            'scaleY':tempHeight/img?.height,
            'name':"FRONT_COVER"
          })
    
          img?.sendToBack()  
    
          img?.setCoords();
     
    
        let frontPath = `M ${tempRightspine} 0 L ${tempWidth} 0 L ${tempWidth} ${tempHeight} L ${tempRightspine} ${tempHeight} z`;
    
          let clipPath1 = new fabric.Path(frontPath, {
            absolutePositioned: true,
            originX: 'center',
            originY: 'center',
            fill: '',
            scaleX: 2,
            scaleY: 2,
            name:'frontClipPath'
          });
       
         
               img?.set({
                        clipPath:clipPath1,
                        controls:true,
                
                      });
                      img.setCoords()
                      canvas?.stage.renderAll()
         
       
         canvas?.stage.requestRenderAll()
        addToHistory(canvas?.stage)
        removeStates()
                    }
    
      }
    
      function handleBackground1(img){
      
        
        img?.set({
           
            'left': 0,
            'top':0,
            // 'width':dimensions.width,
            // 'height':dimensions.height,
            'hasControls':true,
            'selectable':true,
            'scaleX':tempWidth / img.width,
            'scaleY': tempHeight / img.height,
            'name':'FULL_COVER'
          })
    
          img.sendToBack()
          img.setCoords()
    
        
        img?.set({
          clipPath:null,
        });
       
        img.setCoords()
        canvas?.stage.requestRenderAll();
        addToHistory(canvas?.stage)
        removeStates()  
      }

    function handleBack1(img){
     if(tempHeight && tempWidth){
        img.bringToFront()
    
      
        img?.set({
         
            'top': 0,
            'left':0,
            'hasControls':true,
            'selectable':true,
            'scaleX':(((tempWidth- tempSpinewidth)/ 2)/img.width) ,
            'scaleY':tempHeight/img.height,
            'name':'BACK_COVER'
          })
      
         img?.sendToBack()  
          img?.setCoords();
    
        let backPath= `M 0 0 L ${tempLeftspine/2} 0 L ${ tempLeftspine/2} ${tempHeight/2} L 0 ${tempHeight/2}  z`
        let clipPath2 = new fabric.Path(backPath, {
          absolutePositioned: true,
          originX: 'center',
          originY: 'center',
          fill: '',
          scaleX: 2,
          scaleY: 2,
          name:'backClipPath'
        });
         
                    img.set({
                      clipPath:clipPath2,
                    });
                      img.setCoords()
    
    
        canvas?.stage.requestRenderAll()
        addToHistory(canvas?.stage)
        removeStates()
                }
    
    }
    
    function handleSpine1(img){
    
    
      img.set({
         
        'top':0,
        'left':(tempWidth - tempSpinewidth)/ 2,
        'hasControls':true,
        'selectable':true,
        'scaleX':(((tempWidth -tempSpinewidth)/ 2)/img.width) ,
        'scaleY':tempHeight/img.height,
        'name':'SPINE_COVER'
      })
    
      img.setCoords();
      var spinePath = `M ${tempLeftspine} 0 L ${tempRightspine} 0 L ${tempRightspine} ${tempHeight} L ${tempLeftspine} ${tempHeight} z`;
    
      let clipPath3 = new fabric.Path(spinePath,{
        absolutePositioned: true,
        originX: 'center',
        originY: 'center',
        fill: '',
        scaleX:1,
        scaleY: 2,
        name:'spineClipPath'
      });
    
    
       
                  img?.set({
                    clipPath:clipPath3,
                  });
               
                  img.setCoords()
                    props.canvas?.stage.renderAll()
    
    
      canvas?.stage.requestRenderAll()
      addToHistory(canvas?.stage)
      removeStates()
    
    
    }

  useEffect(() => {
    let fontSize1 = Math.round((54 * 300) / 72);
    let fontSize2 = Math.round((40 * 300) / 72);
    let fontSize3 = Math.round((32 * 300) / 72);

    if (can) {
      if (canvas.stage) {
        setSelectedCan(canvas);
        props?.onSuccess(canvas);
        canvas?.addText("Book Title", {
          fontFamily: "Delicious_500",
          top: 100,
          fill: "black",
          name: "book_title",
          fontSize: fontSize1.toString(),
          textAlign: "center",
          width:200
        });

        canvas?.addText("Book Title", {
          fontFamily: "Delicious_500",
          top: 100,
          fill: "black",
          name: "spineTitle",
          fontSize: "40",
          textAlign: "center",
          angle:90,      
          width:500,
          id:"spineTitle"
    
        });

        canvas?.addText("Author", {
          fontFamily: "Delicious_500",
          top: 100,
          fill: "transparent",
          name: "spineAuthor",
          fontSize:"40",
          textAlign: "center",
          angle:90,
          width:500,
          id:"spineAuthor"


        });

        canvas?.addText("Subtitle", {
          fontFamily: "Delicious_500",
          top: 300,
          fill: "black",
          name: "subtitle",
          fontSize: fontSize2.toString(),
          textAlign: "center",
        });

        canvas?.addText("Author", {
          fontFamily: "Delicious_500",
          top: 600,
          fill: "black",
          name: "author",
          fontSize:fontSize3.toString(),
          textAlign: "center",
          // lockMovementX:true,
          // lockMovementY:true
        });

        canvas?.stage.requestRenderAll();

        const found1 = canvas?.stage
          .getObjects()
          .find((item) => item.name === "book_title");
        dispatch({ type: "SELECTED_OBJECT", payload: found1.toJSON() });
        const found2 = canvas?.stage
          .getObjects()
          .find((item) => item.name === "subtitle");
        dispatch({ type: "SELECTED_OBJECT", payload: found2.toJSON() });

        const found3 = canvas?.stage
          .getObjects()
          .find((item) => item.name === "author");
        dispatch({ type: "SELECTED_OBJECT", payload: found3.toJSON() });

        const moveWithKeys = (evt,activeObject)=>{
          var movementDelta = 2;
          if(evt.shiftKey){
            movementDelta = movementDelta+10
          }
          if (evt.keyCode === 37) {
            evt.preventDefault(); // Prevent the default action
            if (activeObject) {
              var a = activeObject.get("left") - movementDelta;
              activeObject.set("left", a);
            }
          } else if (evt.keyCode === 39) {
            evt.preventDefault(); // Prevent the default action
            if (activeObject) {
              const a = activeObject.get("left") + movementDelta;
              activeObject.set("left", a);
            }
          } else if (evt.keyCode === 38) {
            evt.preventDefault(); // Prevent the default action
            if (activeObject) {
              var a = activeObject.get("top") - movementDelta;
              activeObject.set("top", a);
            }
          } else if (evt.keyCode === 40) {
            evt.preventDefault(); // Prevent the default action
            if (activeObject) {
              const a = activeObject.get("top") + movementDelta;
              activeObject.set("top", a);
            }
          }
        }

        const deleteObect = (evt,activeObject)=>{

          if(evt.keyCode == 8 || evt.keyCode == 46){
            activeObject.canvas.remove(activeObject)
          }
          
        }
        var processKeys = function (evt) {
          evt = evt || window.event;


          var activeObject = canvas?.stage?.getActiveObject();
          moveWithKeys(evt,activeObject)
          deleteObect(evt,activeObject)

          if (activeObject) {
            activeObject?.setCoords();
            canvas?.stage?.requestRenderAll();
          }
        };

        var canvasWrapper = document.getElementById("wrapper-canvas");
        if (canvasWrapper) {
          canvasWrapper.tabIndex = 1000;
          canvasWrapper.addEventListener("keydown", processKeys, false);
          canvasWrapper.style.outline = "none";
        }
      }
    }
  }, [can]);

  const [visible,setVisibility]=useState(false)

  useEffect(()=>{
      setTimeout(()=>{
        setVisibility(true)  
      },(300))
    
  },[canvas])

  return (
      <>

      <div className="wrapper" id="wrapper-canvas" style={{visibility:visible?"visible":"hidden",zIndex:"auto"}}>
      { canvas?<Scale canvas={canvas} style={{zIndex:"auto"}} />:null}

        <canvas
          style={{
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            zIndex:1  
          }}
          id="canvas"
          ref={canvasRef}
        ></canvas>
      </div>
    
      </>
  );
};

export default Canvas;
