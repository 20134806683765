
export const toggleEbook=()=>{
    return({
        type:'TOGGLE_EBOOK'
    })
}

export const changePageSize=(pageSize:any)=>{
    return({
        type:'CHANGE_PAGE_SIZE',
        pageSize:pageSize
    })
}

export const changePageNumber=(pageNumber:any)=>{
    return({
        type:'CHANGE_PAGE_NUMBER',
        value:pageNumber
    })
}

export const changeBinding=(binding:string)=>{
    return({
        type:'CHANGE_BINDING',
        value:binding
    })
}

