import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import Popover from "@material-ui/core/Popover";
import {fabric} from 'fabric'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import { addToHistory,removeStates } from "./CanvasHistory.tsx";

const BackgroundImage = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dimensions=useSelector((state:any)=>{return state.dimensions})
   const ebook=useSelector((state:any)=>{return state.ebookToggle})



  function handleFront() {

  
    let img = props.canvas?.stage
    .getActiveObjects()
    .find((item: any) => {
      return item?.type === "image";
    });
    img?.bringToFront()
 
    img?.setCoords()


    img.set({
     
        'top': 0,
        'left':(dimensions.width+dimensions.spinewidth)/2,
        // scaleX: (((dimensions.width + dimensions.spinewidth) / 2) *imgAspectRatio/ img.width),
        // scaleY:dimensions.height*imgAspectRatio/img.height,
        'hasControls':true,
        'selectable':true,
        'scaleX':(((dimensions.width - dimensions.spinewidth)/ 2)/img.width) ,
        'scaleY':dimensions.height/img.height,
        'name':"FRONT_COVER"
      })

      img?.sendToBack()  

      img?.setCoords();
 

    let frontPath = `M ${dimensions.rightspine} 0 L ${dimensions.width} 0 L ${dimensions.width} ${dimensions.height} L ${dimensions.rightspine} ${dimensions.height} z`;

      let clipPath1 = new fabric.Path(frontPath, {
        absolutePositioned: true,
        originX: 'center',
        originY: 'center',
        fill: '',
        scaleX: 2,
        scaleY: 2,
        name:'frontClipPath'
      });
   
     
           img?.set({
                    clipPath:clipPath1,
                    controls:true,
            
                  });
                  img?.setCoords()
                  props.canvas?.stage.renderAll()
     
   
    props.canvas?.stage.requestRenderAll()
    addToHistory(props.canvas.stage)
    removeStates()


  }

  function handleBackground(){
    let img = props.canvas?.stage
    .getActiveObjects()
    .find((item: any) => {
      return item.type === "image";
    });
   
      img?.set({
       
        'left': 0,
        'top':0,
        // 'width':dimensions.width,
        // 'height':dimensions.height,
        'hasControls':true,
        'selectable':true,
        'scaleX':dimensions.width / img.width,
        'scaleY': dimensions.height / img.height,
        'name':'FULL_COVER'
      })

      img?.sendToBack()
      img?.setCoords()

    
    img?.set({
      clipPath:null,
    });
   
    img?.setCoords()
    props.canvas?.stage.requestRenderAll();
    addToHistory(props.canvas.stage)
    removeStates()

  }
  function handleBack(){
    let img = props.canvas?.stage
    .getActiveObjects()
    .find((item: any) => {
      return item.type === "image";
    });
    img?.bringToFront()

  
    img?.set({
     
        'top': 0,
        'left':0,
        'hasControls':true,
        'selectable':true,
        'scaleX':(((dimensions.width - dimensions.spinewidth)/ 2)/img.width) ,
        'scaleY':dimensions.height/img.height,
        'name':'BACK_COVER'
      })
  
     img?.sendToBack()  
      img?.setCoords();

    let backPath= `M 0 0 L ${dimensions.leftspine/2} 0 L ${dimensions.leftspine/2} ${dimensions.height/2} L 0 ${dimensions.height/2}  z`
    let clipPath2 = new fabric.Path(backPath, {
      absolutePositioned: true,
      originX: 'center',
      originY: 'center',
      fill: '',
      scaleX: 2,
      scaleY: 2,
      name:'backClipPath'
    });
     
                img.set({
                  clipPath:clipPath2,
                });
                  img.setCoords()


    props.canvas?.stage.requestRenderAll()
    addToHistory(props.canvas.stage)
    removeStates()


}
function handleSpine(){
  let img = props.canvas?.stage
  .getActiveObjects()
  .find((item: any) => {
    return item.type === "image";
  });

  img.set({
     
    'top':0,
    'left':(dimensions.width -dimensions.spinewidth)/ 2,
    'hasControls':true,
    'selectable':true,
    'scaleX':(((dimensions.width - dimensions.spinewidth)/ 2)/img.width) ,
    'scaleY':dimensions.height/img.height,
    'name':'SPINE_COVER'
  })

  img.setCoords();
  var spinePath = `M ${dimensions.leftspine} 0 L ${dimensions.rightspine} 0 L ${dimensions.rightspine} ${dimensions.height} L ${dimensions.leftspine} ${dimensions.height} z`;

  let clipPath3 = new fabric.Path(spinePath,{
    absolutePositioned: true,
    originX: 'center',
    originY: 'center',
    fill: '',
    scaleX:1,
    scaleY: 2,
    name:'spineClipPath'
  });


   
              img.set({
                clipPath:clipPath3,
              });
           
              img.setCoords()
                props.canvas?.stage.renderAll()


  props.canvas?.stage.requestRenderAll()
  addToHistory(props.canvas.stage)
  removeStates()


}


//   function handleFront1(img) {
 
//     img.bringToFront()
 
//     img.setCoords()


//     img.set({
     
//         'top': 0,
//         'left':(dimensions.width+dimensions.spinewidth)/2,
//         // scaleX: (((dimensions.width + dimensions.spinewidth) / 2) *imgAspectRatio/ img.width),
//         // scaleY:dimensions.height*imgAspectRatio/img.height,
//         'hasControls':true,
//         'selectable':true,
//         'scaleX':(((dimensions.width - dimensions.spinewidth)/ 2)/img.width) ,
//         'scaleY':dimensions.height/img.height,
//         'name':"FRONT_COVER"
//       })

//       img.sendToBack()  

//       img.setCoords();
 

//     let frontPath = `M ${dimensions.rightspine} 0 L ${dimensions.width} 0 L ${dimensions.width} ${dimensions.height} L ${dimensions.rightspine} ${dimensions.height} z`;

//       let clipPath1 = new fabric.Path(frontPath, {
//         absolutePositioned: true,
//         originX: 'center',
//         originY: 'center',
//         fill: '',
//         scaleX: 2,
//         scaleY: 2,
//         name:'frontClipPath'
//       });
   
     
//            img.set({
//                     clipPath:clipPath1,
//                     controls:true,
            
//                   });
//                   img.setCoords()
//                   props.canvas?.stage.renderAll()
     
   
//     props.canvas?.stage.requestRenderAll()
//     addToHistory(props.canvas.stage)
//     removeStates()


//   }

//   function handleBackground1(img){

   
//       img.set({
       
//         'left': 0,
//         'top':0,
//         // 'width':dimensions.width,
//         // 'height':dimensions.height,
//         'hasControls':true,
//         'selectable':true,
//         'scaleX':dimensions.width / img.width,
//         'scaleY': dimensions.height / img.height,
//         'name':'FULL_COVER'
//       })

//       img.sendToBack()
//       img.setCoords()

    
//     img.set({
//       clipPath:null,
//     });
   
//     img.setCoords()
//     props.canvas?.stage.requestRenderAll();
//     addToHistory(props.canvas.stage)
//     removeStates()

//   }
// function handleBack1(img){
 
//     img.bringToFront()

  
//     img.set({
     
//         'top': 0,
//         'left':0,
//         'hasControls':true,
//         'selectable':true,
//         'scaleX':(((dimensions.width - dimensions.spinewidth)/ 2)/img.width) ,
//         'scaleY':dimensions.height/img.height,
//         'name':'BACK_COVER'
//       })
  
//      img.sendToBack()  
//       img.setCoords();

//     let backPath= `M 0 0 L ${dimensions.leftspine/2} 0 L ${dimensions.leftspine/2} ${dimensions.height/2} L 0 ${dimensions.height/2}  z`
//     let clipPath2 = new fabric.Path(backPath, {
//       absolutePositioned: true,
//       originX: 'center',
//       originY: 'center',
//       fill: '',
//       scaleX: 2,
//       scaleY: 2,
//       name:'backClipPath'
//     });
     
//                 img.set({
//                   clipPath:clipPath2,
//                 });
//                   img.setCoords()


//     props.canvas?.stage.requestRenderAll()
//     addToHistory(props.canvas.stage)
//     removeStates()


// }

// function handleSpine1(img){


//   img.set({
     
//     'top':0,
//     'left':(dimensions.width -dimensions.spinewidth)/ 2,
//     'hasControls':true,
//     'selectable':true,
//     'scaleX':(((dimensions.width - dimensions.spinewidth)/ 2)/img.width) ,
//     'scaleY':dimensions.height/img.height,
//     'name':'SPINE_COVER'
//   })

//   img.setCoords();
//   var spinePath = `M ${dimensions.leftspine} 0 L ${dimensions.rightspine} 0 L ${dimensions.rightspine} ${dimensions.height} L ${dimensions.leftspine} ${dimensions.height} z`;

//   let clipPath3 = new fabric.Path(spinePath,{
//     absolutePositioned: true,
//     originX: 'center',
//     originY: 'center',
//     fill: '',
//     scaleX:1,
//     scaleY: 2,
//     name:'spineClipPath'
//   });


   
//               img.set({
//                 clipPath:clipPath3,
//               });
           
//               img.setCoords()
//                 props.canvas?.stage.renderAll()


//   props.canvas?.stage.requestRenderAll()
//   addToHistory(props.canvas.stage)
//   removeStates()


// }
  return (
    <>
      <button
      title="Fit To"
        id="fitTo"
        className="nav-item btn btn-default "
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      > <FontAwesomeIcon icon={faArrowsUpDownLeftRight}/>
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            padding: "10px",
            width: "190px",
            height:!ebook? "220px":"80px",
            color: "grey",
          }}
        >
          {/* <div className=" my-2 px-4 py-1 my-3 hoverItem"
               
          >Set Background</div> */}

        <div
            className=" my-2 px-4 py-1 my-3 hoverItem"
            onClick={handleBackground}
          >Full Cover         
        </div>
        {!ebook?
        <div>
        <div className=" my-2 px-4 py-1 my-3 hoverItem" onClick={handleFront}>
           Front Cover
         </div>

     
         <div
           className=" my-2 px-4 py-1 my-3 hoverItem"
           onClick={handleSpine}
         >
           Spine Cover
         </div>
         <div className=" my-2 px-4 py-1 my-3 hoverItem" onClick={handleBack}>
         Back Cover
         </div>
         </div>
         :null}
       
        </div>
      </Popover>
    </>
  );
};

export default BackgroundImage;
