import React, { useState } from 'react'
import { fabric } from 'fabric'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const Grid = (props) => {
    const [checked, setChecked] = useState(false)
    let dimensions = useSelector((state: any) => {
        return state.dimensions
    })

    const handleChange = () => {
        setChecked(!checked)
    }

    useEffect(() => {
        const gridOptions = {
            distance: 300 * props.canvas?.stage.getZoom() - 1,
            width: dimensions.width,
            height: dimensions.height,
            unit: 'px',
            param: {
                stroke: '#444445',
                strokeWidth: 2,
                selectable: false,
                name: 'grid',
            },
        }
        const gridLen = gridOptions?.width / gridOptions?.distance
        showGridLines(gridOptions, gridLen, checked)

        if (checked === true) {
            props.canvas?.stage.off('object:moving')
            props.canvas?.stage.on('object:moving', function (options) {
                options.target.set({
                    left:
                        Math.round(options.target.left / gridOptions.distance) *
                        gridOptions.distance,
                    top:
                        Math.round(options.target.top / gridOptions.distance) *
                        gridOptions.distance,
                })
            })
        } else {
            props.canvas?.stage.off('object:moving')
        }
    }, [checked])

    const showGridLines = (gridOptions: any, gridLen: any, isSet: boolean) => {
        if (isSet) {
            for (var i = 0; i < gridLen; i++) {
                var distance = i * gridOptions.distance,
                    horizontal = new fabric.Line(
                        [distance, 0, distance, gridOptions.width],
                        gridOptions.param
                    ),
                    vertical = new fabric.Line(
                        [0, distance, gridOptions.width, distance],
                        gridOptions.param
                    )
                props.canvas?.add(horizontal)
                props.canvas?.add(vertical)

                if (i % 5 === 0) {
                    horizontal.set({ stroke: '#444445' })
                    vertical.set({ stroke: '#444445' })
                }
            }

            props.canvas?.stage.renderAll()
        } else {
            let object = []
            props.canvas?.stage.getObjects().find((item) => {
                if (item.name === 'grid') {
                    object.push(item)
                }
            })
            for (let i in object) {
                props.canvas?.stage.remove(object[i])
            }
            props.canvas?.stage.requestRenderAll()
        }
    }
    return (
        <span title="Grid to snap object" id="grid">
            <label>
                <input
                    onChange={handleChange}
                    type="checkbox"
                    checked={checked}
                />
                <span className="mx-2"> Grid</span>
            </label>
        </span>
    )
}

export default Grid
