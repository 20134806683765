import React,{useState} from 'react'
import Popover from "@material-ui/core/Popover";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAlignCenter,faAlignRight,faAlignLeft} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from "react-redux";
import { addToHistory,removeStates } from './CanvasHistory.tsx';


const Alignment = (props) => {
    const [anchorEl,setAnchorEl]=useState(null);
    const open = Boolean(anchorEl);
    let { selectedTextToolBar } = useSelector((state: any) => {
        return state;
      });

    const dimensions=useSelector((state:any)=>{return state.dimensions})
    const paddingDimensions=useSelector((state:any)=>{return state.paddingDimensions})

    const value=useSelector((state:any)=>{return state.value})


     const dispatch=useDispatch();
  

     const handleLeftAlignment=()=>{
        let obj=props.canvas?.stage.getActiveObject()
         if(value==="front")
         {
         obj.set({left:paddingDimensions?.frontpaddingleft.left,top:obj.top})    
         props.canvas?.stage.requestRenderAll()
         }
         if(value==="back")
         {
         obj.set({left:paddingDimensions?.backpaddingleft.left,top:obj.top})    
         props.canvas?.stage.requestRenderAll()
         }
         if(value===null){
            obj.set({left:paddingDimensions?.backpaddingleft.left ,top:obj.top})    
            props.canvas?.stage.requestRenderAll()
         }
      
        //  canvas.width - (activeObj.width * activeObj.scaleX)
         if(value==="spine"){
             obj.set({top:100})
             props.canvas?.stage.requestRenderAll()
         }
         obj.bringToFront()
         obj.setCoords()
         props.canvas.stage.requestRenderAll()
         addToHistory(props.canvas.stage)
         removeStates()

     }

     const handleCenterAlignment=()=>{
        let obj=props.canvas?.stage.getActiveObject()
        if(value==="front")
        {
        obj.set({left:(dimensions.width-dimensions.rightspine)/2+dimensions.rightspine-(obj.width/2),top:obj.top})    
        props.canvas.stage.requestRenderAll()
        }
        if(value==="back")
        {
        obj.set({left:(dimensions.width-dimensions.rightspine)/2-(obj.width/2),top:obj.top})    
        props.canvas.stage.requestRenderAll()
        }
      
        if(value===null){
            obj.set({left:dimensions.width/2-obj.width/2})
            props.canvas.stage.requestRenderAll()
        } 

         if(value==="spine"){
            obj.set({top:dimensions.height/2-obj.width/2})
            props.canvas.stage.requestRenderAll()

        }
        obj.bringToFront()
        obj.setCoords()
        props.canvas.stage.requestRenderAll()
        addToHistory(props.canvas.stage)
        removeStates()

     }

    const handleRightAlignment=()=>{
        //  canvas.width - (activeObj.width * activeObj.scaleX)

        let obj=props.canvas?.stage.getActiveObject()
        // if(value==="front")
        // {
        // obj.set({left:dimensions.width-500,top:obj.top})    
        // props.canvas.stage.requestRenderAll()
        // }
         if(value==="front" || value===null)
        {
        obj.set({left:paddingDimensions.frontpaddingright.left-obj.width,top:obj.top})    
        props.canvas.stage.requestRenderAll()
        }

        if(value==="back")
        {
        obj.set({left:paddingDimensions.backpaddingright.left-obj.width,top:obj.top})    
        props.canvas.stage.requestRenderAll()
        }

        // if(value===null){
        //     obj.set({left:paddingDimensions.frontpaddingright-obj.width,top:obj.top})    
        //     props.canvas.stage.requestRenderAll()
        //  }
         

        if(value==="spine"){
            obj.set({top:dimensions.height-obj.width-100})
            props.canvas.stage.requestRenderAll()

        }
        obj.bringToFront()
        obj.setCoords()
        props.canvas.stage.requestRenderAll()
        addToHistory(props.canvas?.stage)
        removeStates()

    } 
  return (
    <>
      <button title="Alignment"
         style={{
            height: "40px",
            width: "40px",
            border: "solid",
            borderWidth: "0.1px",
            backgroundColor:"white",


            
          }}
      className='' onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}><FontAwesomeIcon icon={faAlignCenter}/>
   </button>    

    <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={()=>{setAnchorEl(null)}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',  
    }}
  >
      <div style={{width:"200px",height:"200px"}}>
    <div className="my-4 px-2">
       <h5 style={{borderBottom:"solid black 1px"}}>Canvas Alignment</h5> 
       <button 
             className='mx-2'
              style={{
                marginLeft: "6px",
                height: "40px",
                width: "40px",
                border: "solid",
                borderWidth: "0.1px",
              }}
       onClick={handleLeftAlignment}
        ><FontAwesomeIcon icon={faAlignLeft}/></button>    

        <button
           className='mx-2'

           style={{
             height: "40px",
             width: "40px",
             border: "solid",
             borderWidth: "0.1px",
           }}
        onClick={handleCenterAlignment}
        >
            <FontAwesomeIcon icon={faAlignCenter}/>
        </button>

        <button
           className='mx-2'

           style={{
             height: "40px",
             width: "40px",
             border: "solid",
             borderWidth: "0.1px",
           }}
        onClick={handleRightAlignment}>
            <FontAwesomeIcon icon={faAlignRight}/>
        </button>
    </div>
    <div className='px-2'>
        <h5 style={{borderBottom:"solid black 1px"}}>Text Alignment</h5>
        <button
        
        className={`mx-2 ${selectedTextToolBar.textAlign==="left"?"active":null}`}



        style={{
          marginLeft: "6px",
          height: "40px",
          width: "40px",
          border: "solid",
          borderWidth: "0.1px",
        }}
        onClick={() => {
          let obj = props.canvas?.stage.getActiveObject();
          obj.set("textAlign", "left");
          props?.canvas.stage?.requestRenderAll();
          dispatch({ type: "SELECTED_OBJECT", payload: obj.toJSON() });

        }}
      >
        {" "}
        <FontAwesomeIcon icon={faAlignLeft} />
      </button>
      <button 
      className={`mx-2 ${selectedTextToolBar.textAlign==="center"?"active":null}`}
        style={{
          height: "40px",
          width: "40px",
          border: "solid",
          borderWidth: "0.1px",
        }}
        onClick={() => {
          let obj = props.canvas?.stage.getActiveObject();
          obj.set("textAlign", "center");
          props?.canvas.stage?.requestRenderAll();
          dispatch({ type: "SELECTED_OBJECT", payload: obj.toJSON() });

        }}
      >

        {" "}
        <FontAwesomeIcon icon={faAlignCenter} />
      </button>
      <button
        className={`mx-2 ${selectedTextToolBar.textAlign==="right"?"active":null}`}
        style={{
          height: "40px",
          width: "40px",
          border: "solid",
          borderWidth: "0.1px",
        }}
        onClick={() => {
          let obj = props.canvas?.stage.getActiveObject();
          obj.set("textAlign", "right");
          props?.canvas.stage?.requestRenderAll();
          dispatch({ type: "SELECTED_OBJECT", payload: obj.toJSON() });
  
        }}
      >
        {" "}
        <FontAwesomeIcon icon={faAlignRight} />
      </button>

    </div>
    </div>
  </Popover>
  </>
  )
}

export default Alignment