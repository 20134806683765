import React,{useState,useCallback} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRotateLeft,faRotateRight} from '@fortawesome/free-solid-svg-icons'
import { CANVAS_STATES,addStateIndex,removeStateIndex } from "./CanvasHistory.tsx";


const UndoRedo = (props) => {

    const handleUndo = useCallback(() => {
        const state = addStateIndex()    
        let json = CANVAS_STATES[state];
        props.canvas?.stage.loadFromJSON(json, () => {
          props.canvas?.stage.renderAll();
        });
      }, [props.canvas]);
    
      const handleRedo=useCallback(()=>{
    const state1=removeStateIndex();
    let json=CANVAS_STATES[state1];
    props.canvas?.stage.loadFromJSON(json,()=>{
        props.canvas?.stage.renderAll();
    });
      },[props.canvas])

  return (
    <>
    <button id="undo"  className="btn btn-outline-dark " onClick={handleUndo}><FontAwesomeIcon icon={faRotateLeft} /></button>
    <button style={{
        }} id="redo" className="btn btn-outline-dark  ms-1" onClick={handleRedo}><FontAwesomeIcon icon={faRotateRight}/></button>
    </>
  )
}

export default UndoRedo