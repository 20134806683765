import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faArrowUpFromBracket,
  faCartShopping,
  faXmark,
  faMountainSun,
} from "@fortawesome/free-solid-svg-icons";
import Pixabay from "./Pixabay.tsx";
import Uploads from "./Uploads.tsx";
import Pexels from "./Pexels.tsx";

const AddImage = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [toggleUpload, setToggleUpload] = useState(false);
  const [togglePixabay, setTogglePixabay] = useState(true);
  const [togglePexels, setTogglePexels] = useState(false);


  return (
    <>
      <button
        title="Add Image"
        id="addImage"
        className="btn btn-default"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {" "}
        <FontAwesomeIcon icon={faImage} />
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
           width:"684px",
            borderTop: "1px solid #D3D3D3",
            borderBottom: "1px solid #D3D3D3",
          }}
        >
          <button
            onClick={() => {
              setToggleUpload(false);
              setTogglePixabay(!togglePixabay);
              setTogglePexels(false);
            }}
            style={{
              backgroundColor: "transparent",
              border: "transparent",
              color: togglePixabay ? "#3D3D3D" : "#817D7D",

              fontWeight: "bold",
              padding: "15px",
            }}
          >
            <FontAwesomeIcon icon={faCartShopping}  />{" "}
            <span>Stock Images</span>
          </button>

 

          <button
            className="nav-item btn btn-default"
            onClick={() => {
              setToggleUpload(!toggleUpload);
              setTogglePixabay(false);
              setTogglePexels(false);
            }}
            style={{
              backgroundColor: "transparent",
              border: "transparent",
              color: toggleUpload ? "#3D3D3D" : "#817D7D",
              fontWeight: "bold",
              padding: "15px",
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faArrowUpFromBracket} /> Uploads
          </button>
          
          <span
            className="crossHover"
            onClick={() => {
              setAnchorEl(null);
            }}
            style={{
           right:20,
           top:10,
           position:"absolute",
              fontSize: "25px",
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </span>
        </div>

          <div style={{display:(togglePixabay?'block':'none')}}>
            <Pixabay setAnchorEl={setAnchorEl}  canvas={props.canvas} /> 
          </div>
          <div style={{display:(toggleUpload?'block':'none')}}>
          <Uploads setAnchorEl={setAnchorEl} canvas={props.canvas} />
          </div>
        
       
      </Popover>
    </>
  );
};

export default AddImage;
