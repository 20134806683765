import React from "react";
import { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Bookspecification from "./Bookspecification.tsx";

const Overlay = (props) => {
  const dispatch = useDispatch();
  const dimensions = useSelector((state: any) => {
    return state.dimensions;
  });

  const ebook = useSelector((helloState: any) => {
    return helloState.ebookToggle;
  });
  let inputPageSize = useSelector((state: any) => {
    return state.pageSize;
  });
  let inputPageNumber = useSelector((state: any) => {
    return state.pageNumber;
  });
  let inputBinding = useSelector((state: any) => {
    return state.binding;
  });
  let finalDimension = useSelector((state: any) => {
    return state.finalDimension;
  });



 const [toggle,setToggle]=useState(false)

useEffect(()=>{
setToggle(true)
props.setBdToggle(true)
},[])
  return (
    <div
      id="bookspecs"
      className={`modal fade ${toggle?"show":null} `}
      style={{display:toggle?'block':''}}
    
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Book Specification
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Done"
              onClick={()=>{
                setToggle(false)
                props.setBdToggle(false)
              }}
             
            ></button>
          </div>

          <div className="modal-body">
            <Bookspecification />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                dispatch({ type: "SET_VALUE", payload: null });

                dispatch({
                  type: "SET_PREVDIMENSIONS",
                  leftspine: dimensions.leftspine,
                  spinewidth: dimensions.spinewidth,
                  height: dimensions.height,
                  width: dimensions.width,
                  rightspine: dimensions.rightspine,
                });

                dispatch({
                  type: "SET_FINAL_DIMENSIONS",
                  pageNumber: inputPageNumber,
                  pageSize: inputPageSize,
                  binding: inputBinding,
                  toggleEbook: ebook,
                });
                setToggle(false)
                props.setBdToggle(false)
                dispatch({type:"SET_DONE"})
                props.canvas?.stage.requestRenderAll();
              }}
            >
              Done
            </button>
            <button
            data-bs-dismiss="modal"
              className="btn btn-danger"
              onClick={() => {
               
                dispatch({
                  type: "CHANGE_PAGE_SIZE",
                  pageSize: finalDimension.pageSize,
                });
                dispatch({
                  type: "CHANGE_PAGE_NUMBER",
                  value: finalDimension.pageNumber,
                });
                dispatch({
                  type: "CHANGE_BINDING",
                  value: finalDimension.binding,
                });
                dispatch({
                  type: "SET_EBOOK",
                  payload: finalDimension.toggleEbook,
                }); 
                setToggle(false)
                props.setBdToggle(false)


              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
